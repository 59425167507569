@use 'sass:map';

/**
 * Set title sizes based on breakpoints.
 *
 * @param {String} $size - The title selector (h1, h2, etc)
 * @param {Map} $sizes - The size map for the title and its breakpoints
 */
@mixin title-size($size, $sizes) {
    #{$size} {
        font-size: map.get($sizes, base);

        @each $breakpoint in map-keys($breakpoints) {
            @include breakpoint($breakpoint) {
                font-size: map.get($sizes, $breakpoint);
            }
        }
    }
}

@each $size, $value in $font-sizes-title {
    @include title-size($size, $value);
}
