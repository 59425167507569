header {
  position: relative;

  .header-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $blue_5;
    padding: 2.4rem 0;

    .logo-header {
      a {
        width: 10rem;
        height: 6rem;
        display: block;

        svg {
          color: $white;
          height: 100%;
        }
      }
    }

    .like-btn {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        border-radius: 5rem;
        background: $linear-gradient-secondary;

        svg {
          margin-top: 0.2rem;
          color: $white;
          width: 60%;
          height: 60%;
        }
      }
    }

    .account-and-favorite {
      display: flex;
      align-items: center;
    }

    .user-btn {
      margin-left: 3rem;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;

        svg {
          transition: color 0.5s;
          color: $opacity;
          height: 100%;
        }

        &:hover {
          svg {
            color: $white
          }
        }
      }
    }

    .burger-menu {
      position: relative;
      z-index: 999999;

      display: flex;
      flex-direction: column;
      cursor: pointer;
      border: 0;

      span {
        transition: transform 0.5s;
        margin: 0.2rem 0;
        width: 2.5rem;
        height: 0.3rem;
        border-radius: 0.5rem;
        background-color: $white;
        border: 1px solid $white;
      }

      &.active {
        span:nth-child(1) {
          transform: translateY(6px) rotateZ(-0.125turn);
          transition: transform 0.5s;
        }

        span:nth-child(2) {
          transform: rotateX(0.25turn);
          transition: transform 0.3s;
        }

        span:nth-child(3) {
          transform: translateY(-8px) rotateZ(0.125turn);
          transition: transform 0.5s;
        }
      }

    }
  }

  .header-menu {
    position: absolute;
    width: 100%;
    height: calc(100vh);
    top: 0;
    left: 100%;
    transition: left 0.5s ease;

    background-color: $blue_5;
    z-index: 100;
    padding-top: 10.8rem;
    overflow-x: hidden;


    .nav-menu {
      width: 100%;

      ul {
        display: flex;
        flex-flow: row wrap;
        width: 27.6rem;
        margin: 0 auto;

        li {
          margin-bottom: 1.6rem;

          &:nth-of-type(2n) {
            margin-left: 1.6rem;
          }
        }

        > div {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        a {
          display: block;
          color: $white;
          text-decoration: none;
          font-size: 1.4rem;
          text-align: center;
          transition: color 0.5s;

          &:hover {
            color: $blue_6;
          }


          svg {
            color: $blue_6;
          }
        }

        .item-with-svg {
          width: 13rem;
          height: 9rem;
          padding: 1rem 2rem;
          background-color: rgba(255, 255, 255, 0.10);
          border: 1px solid rgba(255, 255, 255, 0.10);

          border-radius: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: 1;
          transition: border 0.5s;

          svg {
            width: 3.7rem;
            height: 3.7rem;
            display: block;

            use {
              width: 100%;
              height: 100%;
            }
          }

          &:hover, &:active, &:focus, &:target, &:focus-visible {
            border: 1px solid $blue_6;
          }
        }

        .itemAlone {
          width: 13rem;

        }
      }
    }

    .header-rs {
      position: relative;
      width: 100%;
      max-width: 40rem;
      margin: auto;
      height: 35rem;

      .cars-and-hearth {
        position: absolute;
        top: 0;
        left: 20%;
        width: 36rem;

        svg {
          color: $blue_6;

          path {
            stroke: $blue_6;
          }
        }

        img {
          position: absolute;
          top: 4rem;
          left: 10rem;
          width: 25.2rem;
        }
      }

      .logo-rs {
        position: relative;
        top: 40%;
        left: 35%;
        z-index: 2;
        display: flex;
        //justify-content: end;
        align-items: center;

        p {
          margin-right: 1rem;
        }

        a {
          display: block;
          width: 2rem;
          height: 2rem;
          font-size: 1.2rem;
          font-weight: 700;
          margin-right: 1rem;

          svg {
            color: $blue_5;
            height: 100%;
            transition: color 0.5s;
          }

          &:hover {
            svg {
              color: $white;
            }
          }
        }
      }
    }
  }

  .header-menu.active {
    left: 0;
  }
}


@media screen and (min-width: 1280px) {
  header {
    .header-top {
      padding: 3rem;
      justify-content: center;

      .logo-header {
        a {
          width: 15rem;
          height: 9rem;
        }
      }

      .burger-menu {
        display: none;
      }

      .account-and-favorite {
        position: absolute;
        top: calc(3rem - 0.5rem);
        //left: 50%;
        //transform: translateX(-50%);
        //margin-left: 35rem;
        z-index: 999;
        .user-btn{
          margin-left: 2rem;
        }
      }
    }

    .header-menu {
      position: relative;
      height: auto;
      display: flex;
      flex-direction: column;
      padding-top: 0;
      width: auto;
      left: 0;

      .nav-menu {
        order: 1;

        ul {
          width: auto;
          justify-content: flex-start;
          flex-flow: row nowrap;
          margin: 0;

          li {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            position: relative;

            &.accueil {
              order: -1;
            }

            &:nth-of-type(2n) {
              margin-left: 0;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 0.1rem;
              height: 2rem;
              background-color: $blue_6;

            }
          }

          .item-with-svg, .itemAlone {
            width: auto;
            height: auto;
            background: none;
            border: none;
            padding: 1rem 2rem;
            transition: color 0.7s;

            &:hover {
              border: none;
              color: $blue_6;
            }

            svg {
              display: none;
            }
          }

        }

      }

      .header-rs {
        width: auto;
        height: auto;
        margin-right: 0;
        order: 0;
        margin-bottom: 3rem;

        .cars-and-hearth {
          display: none;
        }

        .logo-rs {
          width: auto;
          max-width: none;
          top: initial;
          left: initial;

          p {
            display: none;
          }

          a {
            height: 3rem;
            width: 2rem;
            padding: 0.5rem 0;
            margin-right: 0;
            margin-left: 2rem;

            svg {
              color: $white;
            }

            &:hover {
              svg {
                color: $blue_6;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  header {
    .header-menu {
      .nav-menu {
        ul {
          li {
            a {
              font-size: 1.6rem;
            }

            &:after {
              height: 3rem;
            }
          }
        }
      }
    }
  }
}