@import "../_info-dot.scss";

.cars-card {
  width: 28rem;
  min-height: 55.7rem;
  display: flex;
  flex-flow: column;
  border-radius: 1.2rem;
  font-family: REM;


  .top-card {
    display: flex;
    border-top-right-radius: 1.2rem;
    border-top-left-radius: 1.2rem;

    .fav-container {
      background-color: $blue_5;
      display: block;
      position: relative;
      border-top-left-radius: 1.2rem;


      .favoritePop {
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        top: 10px;
        left: 50%;
        opacity: 0;
        transform: translateX(-50%);
        animation: pop-like 1s ease forwards;
        user-select: none;
        pointer-events: none;

        &.one {
          transform: rotate(-10deg) translateX(-50%);
        }

        &.two {
          transform: rotate(8deg) translateX(-50%);
        }


        svg {
          width: 100%;
          color: $blue_6;
        }

        @keyframes pop-like {
          0% {
            left: 50%;
            top: 10px;
          }
          20% {
            opacity: 1;
          }
          80% {
            top: -20px;
          }
          100% {
            opacity: 0;
          }
        }
      }

      .favorite-btn {
        width: 4.8rem;
        min-height: 100%;
        padding: 1.4rem;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
          @keyframes -beat {
            0% {
              transform: scale(1);
            }
            40% {
              transform: scale(1.4);
            }
            60% {
              transform: scale(1);
            }
            80% {
              transform: scale(1.2);
            }
            100% {
              transform: scale(1);
            }
          }
          animation: -beat 0.5s;
          animation-delay: 1.2s;

          svg {
            path {
              fill: $blue_6;
            }
          }
        }

        &:hover {
          svg {
            path {
              stroke: $blue_6;
            }
          }
        }

        svg {
          width: 100%;
          display: block;

          path {
            transition: all 0.5s;
            color: rgba(0, 255, 209, 0);
          }

          linearGradient {
            stop {
              transition: 0.5s;
            }
          }
        }
      }

    }

    .title-card {
      background-color: $white;
      text-align: left;
      padding: 0.7rem 1rem;
      flex-grow: 1;
      border-top-right-radius: 1.2rem;
      line-height: 1;


      h3, p {
        margin: 0;
      }

      h3 {
        font-size: 1.6rem;
        font-weight: 900;
        line-height: normal;
        font-style: italic;
        // limité à 1 ligne
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      p {
        font-size: 1.4rem;
        font-weight: 600;
        color: $blue_1;
        // limité à 1 ligne
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
      }


    }
  }

  .front-card {
    position: relative;
    z-index: 1;
    flex-grow: 1;

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 15.3rem;
      transition: height 0.9s;
      overflow: hidden;
      position: relative;



      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      .negotiation-pastille{
        position: absolute;
        top: 1rem;
        right: 0.5rem;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $blue_7;
        padding: 0.5rem;
        border: 1px solid $blue_7;
        z-index: 1;
        font-size: 1.2rem;
        border-radius: 0.5rem;
        p{
          margin: 0;
        }

      }

    }

    .price-info {
      display: flex;
      justify-content: center;
      background-color: $blue_5;
      transition: height 0.5s;
      overflow-y: hidden;
      height: 7.5rem;


      h4 {
        font-size: 1.6rem;
        text-transform: uppercase;
        text-align: center;
        color: $blue_6;
        margin: 0;
      }

      p {
        color: $white;
        margin-bottom: 0rem;
      }

      .financement {
        width: 60%;
        padding: 0.9rem 0 0.7rem;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 0.1rem;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 60%;
          background-color: $white;
          margin-bottom: 0.5rem;
        }

        p {
          width: 100%;
          display: inline-flex;
          flex-direction: row;
          font-size: 1.4rem;
          align-items: center;
          justify-content: center;
          margin: 0.5rem 0 0;
          line-height: 1.2;

          .price {
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 1;
          }

          .ttc {
            font-weight: 300;
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            font-size: 1.4rem;
            display: flex;
            align-items: center;

            span {
              width: 2.5rem;
              margin-top: -0.4rem;
            }
          }

          svg {
            margin-left: 0.5rem;
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }

      .achat {
        position: relative;
        width: 40%;
        padding: 0.9rem 0 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: center;


        p {
          display: inline-flex;
          flex-direction: column;
          text-align: center;
          font-size: 1.8rem;
          font-weight: 700;
          margin: 0.5rem 0 0;
          line-height: 1.2;

          span {
            font-size: 1.4rem;
            font-weight: 300;
          }

        }
      }
    }

    .summary-front {
      transition: height 0.5s, padding 0.5s;
      height: 20rem;
      overflow-y: hidden;
      width: 100%;
      position: relative;
      background-color: $white;
      display: flex;
      flex-flow: row wrap;
      font-size: 1rem;
      padding: 0.9rem 1rem;

      .summary-item {
        width: calc(100% / 3);
        padding: 1rem 0.5rem;
        margin-bottom: 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;


        p {
          margin: 0;
          font-size: 1.4rem;
        }
      }

      svg {
        color: $blue_1;
        width: 3rem;
        height: 3rem;
      }

      &:before {
        content: "";
        position: absolute;
        width: 90%;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        height: 1px;
        border-radius: 1px;
        background-color: $blue_5;
      }
    }

    .bottom-card {
      width: 100%;
      padding-bottom: 1rem;

      .background-bottom-card {
        display: flex;
        //background-color: $white;
      }

      .compare {
        width: 45%;
        padding-left: 5%;
        background-color: $white;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 1rem;
        position: relative;
        z-index: 1;

        h4 {
          font-size: 1.4rem;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 0;
          margin-top: 0.8rem;
          text-align: left;
        }

        .compare-btn {
          position: relative;
          cursor: pointer;
          background-color: $blue_5;
          width: 5.9rem;
          height: 2.4rem;
          border-radius: 5rem;
          overflow: hidden;
          box-shadow: 0.75px 0.75px 2px 0px rgba(0, 0, 0, 0.50) inset;
          transition: background-color 0.4s;
          margin-bottom: 0.8rem;
          user-select: none;


          &:hover {
            .swicth-content {
              span {
                color: darken($blue_6, 15%);
              }
            }
          }

          &.active {
            background-color: $blue_6;

            &:hover {
              background-color: $blue_6;

              .swicth-content {
                span {
                  color: darken($blue_5, 15%);
                }
              }
            }

            .swicth-content {
              left: 8%;

              span {
                color: $blue_5;
              }
            }
          }

          .swicth-content {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            //left: 10%;
            left: -50%;
            transition: left 0.4s;
            transform: translateY(-50%);

            span {
              display: inline-block;
              text-align: center;
              width: 3rem;
              font-size: 1.2rem;
              font-weight: 800;
              color: $blue_6;
              transition: color 0.4s;
              margin-left: 0.2rem;
            }

            svg {
              max-width: unset;
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }

      .container-more {
        width: 20%;
        flex-grow: 1;
        background-color: $white;
        display: flex;
        align-items: flex-end;

        .more-btn {
          width: 100%;
          height: 2.4rem;
          border-top-right-radius: 5rem;
          border-top-left-radius: 5rem;
          background-color: $blue_5;
          position: relative;
          cursor: pointer;

          &:hover {
            span {
              background-color: darken($blue_6, 15%);
            }
          }


          span {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            background-color: $blue_6;
            width: 1.1rem;
            height: 0.3rem;
            transition: transform 0.4s 0.8s, background-color 0.5s;

            &:first-of-type {
              transform: rotate(90deg) translate(45%, 180%);
            }

            &:last-of-type {
              transform: translate(-50%, 160%);
            }
          }
        }
      }

      .detail-page {
        width: 45%;
        padding-right: 5%;
        padding-left: 5%;
        background-color: $white;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 0.5rem;
        text-align: center;

        a {
          cursor: pointer;
          text-decoration: none;
          font-size: 1.4rem;
          font-weight: 700;
          text-transform: uppercase;
          position: relative;
          overflow: hidden;
          border: 1px solid $blue_5;
          display: inline-flex;
          align-items: center;
          background-color: $blue_5;
          color: $blue_6;
          border-radius: 0.5rem;
          padding: 0.4rem 0.4rem;
          margin: 0.8rem 0;
          transition: background-color 0.4s, color 0.4s;
          line-height: 1.2;

          &:hover {
            color: darken($blue_6, 15%);
          }

          &:active, &:focus {
            background-color: $blue_6;
            color: $blue_5;
          }

          svg {
            position: absolute;
            bottom: 0.8rem;
            right: 0rem;
            margin-left: 0.5rem;
            width: 1.2rem;
            height: 1rem;
            max-width: unset;
            transform: rotate(-90deg);
          }
        }
      }
    }

    .summary-back {
      background-color: $blue_5;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 1.4rem;
      padding: 40% 1rem 20% 1rem;
      border-bottom-right-radius: 1.8rem;
      border-bottom-left-radius: 1.8rem;

      .summary-item {
        width: calc(100% / 3);
        margin-bottom: 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        p {
          margin: 0;
          color: $white;
        }
      }

      svg {
        color: $blue_6;
        width: 3rem;
        height: 3rem;
      }
    }
  }

}


// card active
.cars-card {

  &.active {
    .front-card {
      .img-container {
        height: 0;
      }

      .price-info {
        height: 0;
      }

      .summary-front {
        height: 0;
        padding: 0 1rem;
      }

      .bottom-card {
        .container-more {

          .more-btn {
            span {
              &:first-of-type {
                transform: rotate(45deg) translate(-40%, 240%);
              }

              &:last-of-type {
                transform: rotate(-45deg) translate(-30%, -15%);
              }
            }
          }
        }
      }
    }
  }
}

.container-car-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offer-container {
  margin-right: auto;
  margin-left: auto;
  margin-top: 1.5rem;
  display: inline-block;
  position: relative;
  z-index: 5;
  overflow: hidden;
  padding: 2px;
  border-radius: 0.5rem;


  .offer-btn {
    margin: auto;
    display: block;
    position: relative;
    overflow: hidden;
    user-select: none;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 0.5rem;


    transition: border 0.5s, color 0.5s;

    border: 2px solid $blue_6;
    color: $blue_6;
    background: rgba(0, 0, 0, 0);


    &:before {
      content: "";
      position: absolute;
      width: 0.1rem;
      box-shadow: 0 0 1rem 1rem $white;
      height: 5rem;
      left: -50%;
      top: -40%;
      transform: rotate(20deg);
      background: $white;
      z-index: 1;
      animation: shiningOffer 3s ease infinite;



    }

    &:hover {
      border: 2px solid rgba(255, 255, 255, 1);
      background-color: $blue_1;
      color: $white;

      &:before {
        animation: none;
      }
    }
  }

}

@keyframes shiningOffer {
  0% {
    left: -50%;
  }
  30% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}