.pagination {
  display: inline-flex;
  font-size: 2rem;
  padding: 0.5rem 2rem;
  border: 2px solid $blue_6;
  border-radius: 1rem;

  &.alternative {
    border-color: $white;

    a {
      color: $white;

      &:hover {
        background-color: darken($white, 10%);
        color: $blue_5;
      }

      &.current {
        background-color: $white;
        color: $blue_5;
      }
    }
  }

  a {
    display: block;
    width: 3rem;
    height: 3rem;
    margin: 0 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $blue_6;
    border-radius: 0.5rem;

    &:hover {
      background-color: darken($blue_6, 10%);
      color: $blue_5;
    }

    &.current {
      background-color: $blue_6;
      color: $blue_5;
    }
  }
}