@import "../components/cards/_cars-card.scss";

.nos-likes-du-moment-section {
  background: linear-gradient(0deg, rgba(25, 46, 49, 1) 0%, rgba(50, 102, 109, 1) 35%, rgba(46, 93, 99, 1) 65%, rgba(25, 46, 49, 1) 100%);
  width: 100%;
  padding: 3rem 0;

  .title-section {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;



    h2 {
      color: unset;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 800;
      display: flex;
      flex-direction: row;
      line-height: 1;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $white;

      >div{
        display: flex;
        align-items: center;
        justify-content: center;

      }
      span{
        font-size: 1.6rem;
        text-align: center;
        margin-top: 0.4rem;
        background: linear-gradient(-65deg,white 0%, white 48%, rgba(0, 255, 209, 0.9) 50%, white 52% ,white 100%) no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 400%;
        -webkit-animation-name: shineEffectText;
        -webkit-animation-duration: 4s;
        -webkit-animation-iteration-count: infinite;
      }

      @-webkit-keyframes shineEffectText {
        0%{
          background-position: 100%;
        }
        100% {
          background-position: 0%;
        }
      }

      svg {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        color: $blue_7;
        animation: titleBeat 4s infinite;
      }

      @keyframes titleBeat {
        0% {
          transform: scale(1);
        }
        5% {
          transform: scale(1.4);
          color: $blue_6;
        }
        10% {
          color: $blue_7;
          transform: scale(1);
        }
        15% {
          transform: scale(1.2);
          color: $blue_6;
        }
        20% {
          transform: scale(1);
          color: $blue_7;
        }
        100% {
          color: $blue_7;

          transform: scale(1);
        }
      }
    }
  }

  .swiper-container {
    width: 100%;
    //max-width: 8rem;
    position: relative;

    .swiper {
      width: 100%;


      .swiper-wrapper {

        .swiper-slide {
          //width: 24.4rem !important;
          display: flex !important;
          justify-content: center;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }

    .swiper-navigation {
      display: none;
      overflow: hidden;
      position: absolute;
      top: 50%;
      width: 5rem;
      height: 3rem;
      align-items: center;
      justify-content: center;
      z-index: 5;
      color: white;
      //background-color: red;
      cursor: pointer;
      transition: color 0.5s;
      user-select: none;

      &:hover {
        color: $blue_6;
      }

      svg {
        width: 100%;
      }

      &.swiper-button-prev {
        right: 100%;
        transform: translateY(-50%) rotate(90deg);
      }

      &.swiper-button-next {
        left: 100%;
        transform: translateY(-50%) rotate(-90deg);

      }
    }
  }
}

@media screen and (min-width: 730px) {
  .nos-likes-du-moment-section {
    background: radial-gradient(circle, rgba(50, 102, 109, 1) 0%, rgba(25, 46, 49, 1) 80%);
    padding: 6rem 0 6rem;
    position: relative;

    .title-section {
      margin-left: 0;
      margin-bottom: 3rem;
      width: 100%;
      justify-content: center;


      h2 {
        font-size: 3rem;
        span{
          font-size: 2rem;
        }

        svg {
          width: 4rem;
          height: 4rem;
          margin-right: 1.5rem;
        }
      }
    }

    .swiper-container {
      width: 80%;
      margin: auto;

      .swiper {
        padding: 5rem 0 !important;

        &:before, &:after {
          // un ligne qui prend 100% de la largeur et 1px de hauteur
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background: $blue_6;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }
      }

      .swiper-navigation {
        display: flex;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .nos-likes-du-moment-section {
    padding: 7rem 0;

    .title-section {
      h2 {
        font-size: 4rem;

        span{
            font-size: 2.4rem;
        }
        svg{
          width: 5rem;
          height: 5rem;
        }
      }
    }

    .swiper-container {
      max-width: 100rem;
    }

  }
}

@media screen and (min-width: 1500px) {
  .nos-likes-du-moment-section {
    .swiper-container {
      max-width: 127rem;

      .swiper-navigation {
        &.swiper-button-prev {
          right: 105%;
        }

        &.swiper-button-next {
          left: 105%;
        }
      }

    }
  }
}

@media screen and (min-width: 1920px) {
  .nos-likes-du-moment-section {
    .title-section {
      h2 {
        font-size: 4rem;
      }
    }
  }

}
