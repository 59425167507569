#pop-up-comparaison {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $blue_5;
  z-index: 999999999;
  padding: 3rem 5%;
  color: $white;
  border-bottom: 3px solid $blue_6;

  .toolbar-mode {
    display: none;
  }

  &.reduce {
    width: 6rem;
    height: 6rem;
    padding: 0;
    top: unset;
    top: calc(100% - 15rem);
    left: auto;
    right: 1.6rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $linear-gradient-flashy;
    border: 2px solid $white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    transition: top 0.4s;
    &:hover{
      top: calc(100% - 15.5rem);

    }



    .toolbar-mode {
      width: 100%;
      height: 100%;
      margin-left: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 3.4rem;
        height: 3.4rem;
        pointer-events: none;
        color: $blue_5;
      }
    }

    .content-pop-up {
      opacity: 0;
      transform: scale(0);
      width: 0;
      transition: none;
    }
  }

  .content-pop-up {
    transition: opacity 0.5s, transform 0.5s;
    max-width: 80rem;
    margin: 0 auto;
    opacity: 1;
  }

  &.hidden {
    display: none;
  }

  .top-pop-up {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 1.6rem;
      margin: 0;
    }

    #close-comparaison {
      border: none;
      height: 3rem;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .mid-pop-up {
    margin: 3rem auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 60rem;


    .comparaison-card {
      width: 30%;
      height: 35vw;
      max-height: 20rem;
      max-width: 15rem;
      border-radius: 1rem;
      background-color: $white;
      position: relative;
      transition: background-color 0.5s;

      .cross {
        cursor: pointer;
        z-index: 2;
        opacity: 1;
        color: rgba(255, 255, 255);
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, -50%) rotate(0deg);
        border-radius: 100%;
        background-color: $blue_5;

        &:hover {
          background-color: #ED4C5C;
        }

        transition: transform 0.3s, top 0.5s, background-color 0.5s, width 0.3s, height 0.3s, opacity 0s;

        svg {
          width: 1.3rem;
          height: 1.3rem;
          color: $white;
          transition: width 0.5s, height 0.5s;
        }
      }

      .content {
        opacity: 1;
        transition: opacity 0.5s 0.4s;
        display: flex;
        flex-direction: column;
        height: 100%;

        > div {
          width: 100%;
          height: 45%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
          }
        }

        p {
          font-size: 1.2rem;
          line-height: 1;
          text-transform: uppercase;

          &.model {
            margin: 1rem 5% auto;
            font-weight: 800;
            color: $blue_5;
            -webkit-line-clamp: 2;
            font-style: italic;
          }

          &.price {
            margin: auto 5% 1.2rem;
            font-weight: 700;
            color: $blue_1;
            text-align: center;
          }

          color: $blue_1;
          font-weight: 700;
        }

        @media screen and (min-width: 425px) {
          p {
            font-size: 1.6rem;

            &.model {
              margin: 1rem 5% 1rem;
            }

            &.price {
              margin: auto 5% 3rem;
            }
          }
        }

      }

      &.empty {
        border: 2px dashed $blue_7;
        background-color: rgba(255, 255, 255, 0);

        .cross {
          opacity: 1;
          color: rgba(255, 255, 255, 0.8);
          width: 6rem;
          height: 6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          border-radius: 100%;
          background-color: rgba(255, 0, 0, 0);
          cursor: initial;
          border: 2px solid rgba(255, 255, 255, 0);

          &:hover {
            border: 2px solid rgba(255, 255, 255, 0);

          }

          svg {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        .content {
          opacity: 0;
        }
      }
    }
  }

  .bottom-pop-up {
    color: $blue_7;
    text-align: center;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;

    p {
      margin: 0;
    }

    button {
      cursor: pointer;
      color: $blue_5;
      text-transform: uppercase;
      background-color: $blue_6;
      border: 2px solid $white;
      border-radius: 1rem;
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem;
      font-size: 1.6rem;
      font-style: italic;
      font-weight: 900;
      transition: color .5s, background-color .5s;
      display: flex;

      &:hover {
        color: $white;
        background-color: $blue_5;
        border: 2px solid $blue_6;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #pop-up-comparaison {
    .top-pop-up {
      h2 {
        font-size: 2.4rem;
      }
    }

  }
}
