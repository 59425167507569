@import "../components/title-page-part";

.simple-page {
  background-color: $white;

  .container {
    padding: 0 10% 3rem;

    h2 {
      position: relative;
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 2rem;
      margin-left: 1rem;
      color: $white;
      line-height: 1.2;
      padding: 1rem 2rem;
      background-color: $blue_5;
      border-radius: 0 0 1rem 0;

      &:before {
        position: absolute;
        content: '';
        width: 1rem;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        left: -1rem;
        background: linear-gradient(to bottom, #24FA94 0%, #25E5DA 100%);
      }
    }
  }
}

.steps-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .step {
    width: 100%;
    margin: 3rem 0 3rem;
    p{
      margin-bottom: 0;
    }

    .step-title {
      font-size: 1.8rem;
      font-weight: 500;
      color: $blue_5;
      margin-bottom: 1.6rem;
      display: flex;
      flex-direction: row;

      > div {
        background: linear-gradient(45deg, #25E5DA 0%, #24FA94 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1.2rem;

        p {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: $blue_5;
          text-transform: uppercase;
          font-size: 1.4rem;
          line-height: 1;
          font-weight: 700;
          width: auto;
          margin: auto 0;

          span {
            font-size: 3rem;
          }
        }
      }

      h3 {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        flex: 1;
        color: $white;
        font-style: italic;
        font-weight: 800;
        margin: 0;
        line-height: 1.2;
        padding: 1.2rem 2rem;
        font-size: 1.8erm;
        background-color: $blue_5;
        border-bottom-right-radius: 1rem;

        span {
          font-style: normal;
          font-size: 3rem;
          color: $blue_6;
          margin-bottom: -0.4rem;
        }
      }

    }
  }
}

@media screen and (min-width: 764px) {
  .simple-page {
    .container {
      padding: 0 15% 3rem;

      h2 {
        font-size: 2.2rem;
      }

      p {
        margin-bottom: 3.2rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }



}

@media screen and (min-width: 1280px) {
  .simple-page {
    .container {
      padding: 0 20% 9rem;

      p {
        margin-bottom: 6rem;
        line-height: 1.6;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

    }

  }
  .steps-container {
    justify-content: space-between;
    .step {
        width: 45%;
      p{
        margin-bottom: 0;
      }

      .step-title {
        font-size: 2rem;

        > div {
          align-items: center;
          padding: 0.5rem 1.2rem;

          p {
            font-size: 1.6rem;
            span {
              font-size: 3rem;
            }
          }
        }

        h3 {
            font-size: 1.8rem;

          span {
            font-size: 3rem;
          }
        }

      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .simple-page {
    .container {
      h2 {
        font-size: 2.6rem;
      }

      p {
        font-size: 1.8rem;
      }
    }
  }

  .steps-container {

    .step {
      .step-title {
        font-size: 2rem;
        > div {
          p {
            font-size: 1.8rem;
            span {
              font-size: 3.2rem;
            }
          }
        }

        h3 {
          font-size: 2rem;
          span {
            font-size: 3.4rem;
          }
        }
      }
    }
  }
}