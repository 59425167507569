.container-occasions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6rem 0;
  background-color: #192E31;

  .all-occasions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    margin: auto;

    .container-car-card {
      position: relative;
      margin: 0 auto 4.5rem;
      z-index: 2;

      .cars-card {
        filter: drop-shadow(0px 0px 3rem #38666C);
      }
    }
  }

}

@media screen and (min-width: 764px) {
  .container-occasions {
    .all-occasions {
      justify-content: flex-start;
      align-items: flex-start;
      width: 80%;

      .container-car-card {
        margin: 0 0rem 4.5rem;

        &:nth-of-type(2n) {
          margin-left: calc(100% - 56rem);
        }
      }

      @media screen and (min-width: 1080px) {
        .container-car-card {
          margin-left: 0rem;

          &:nth-of-type(2n) {
            margin-left: 0rem;
          }

          &:nth-of-type(3n - 1) {
            margin-left: calc((100% - 3 * 28rem) / 2);
            margin-right: calc((100% - 3 * 28rem) / 2);
          }
        }
      }
      @media screen and (min-width: 1760px) {
        .container-car-card {
          margin-left: 0rem;

          &:nth-of-type(3n - 1) {
            margin-left: 0;
            margin-right: 0;
          }

          &:nth-of-type(4n - 2), &:nth-of-type(4n - 1), &:nth-of-type(4n){
            margin-left: calc((100% - 4 * 28rem) / 3);

          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px){
    .container-occasions {
        .all-occasions {
        width: 70%;
          max-width: 135rem;
        }
    }
}
