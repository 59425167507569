.checkbox, .radio {
  display: flex;
  align-items: center;
  margin: 1.6rem 0;

  input[type="checkbox"], input[type="radio"] {
    display: none;
  }

  label {
    position: relative;
    font-size: 1.4rem;
    display: inline-block;
    align-items: flex-start;
    margin-left: 2.4rem;

    &:before {
      content: '';
      position: absolute;
      left: -2.4rem;
      cursor: pointer;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $white;
      border: 2px solid $white;
      border-radius: 100%;
      margin-right: 0.8rem;
      margin-top: 0.2rem;
    }
  }

  // quand l'input est checké
  input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
    &:before {
      background-color: $blue_1;
    }
  }
}

.radio {
  label {
    margin-right: 2rem;
  }

  &.card {
    flex-flow: column;
    align-items: flex-start;
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 3.1rem);
      max-width: 25rem;
      background-color: $white;
      color: $blue_5;
      padding-left: 1rem;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      min-height: 6rem;
      margin: 1rem 0 1rem 3.1rem;
      &:before {
        z-index: 2;
      }
      &:after {
        content: '';
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        z-index: 1;
        position: absolute;
        right: 100%;
        top: 0;
        width: 3.1rem;
        height: 100%;
        background-color: $blue_7;
      }

      p {
        margin: 0;
        padding: 2rem 0.1rem;
        text-align: left;
        .blue{
            color: $blue_1;
            font-size: 1.2rem;
        }
      }

      .price {
        display: flex;
        align-self: stretch;
        background-color: $blue_7;
        justify-content: center;
        align-items: center;
        min-width: 6rem;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;

      }
      img{
        width: 6rem;
        margin-left: 1.4rem;
        margin-right: 1rem;
      }
    }
  }
}

input, textarea {
  font-size: 1.4rem;
  border-radius: 1rem;
  border: 1px solid #000;
  padding: 0.6rem 1.2rem;
  background-color: $white;
  color: $blue_5;
}

select{
  font-size: 1.4rem;
  border-radius: 1rem;
  border: 1px solid #000;
  padding: 0.6rem 1.2rem;
  background-color: $white;
  color: $blue_5;
}

button[type="submit"] {
  cursor: pointer;
  color: $blue_5;
  font-weight: 600;
  background-color: $blue_7;
  border: 2px solid $white;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
}