@import "../utilities/_text.scss";
@import "../components/_button.scss";

.estimation-title {
  background: radial-gradient(circle, #fff 50%, #ACC5CD 100%);
  padding: 3rem 10%;

  .container-img-tilte {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    img {
      width: 100%;
      min-width: 17.6rem;
      max-width: 20rem;
    }

    h1 {
      font-family: 'Arvo', sans-serif;
      font-size: 2.4rem;
      font-weight: 700;
      width: 20rem;
      line-height: 1.2;
    }
  }

  .container-step-info {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;

    .trait {
      width: 10%;
      height: 0.1rem;
      background-color: $blue_3;
      margin-top: 1.5rem;
    }

    .step-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 100%;

      .circle {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        color: $blue_3;
        border: 1px solid $blue_3;
        font-size: 1.6rem;
        font-weight: 700;
        background-color: rgba(255, 255, 255, 0);
        transition: background-color 0.4s, color 0.4s;
        &:before{
          content: 'Étape';
          position: absolute;
          border-radius: 100%;
          bottom: 85%;
          color: $blue_3;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      p {
        color: $blue_3;
        font-size: 1.2rem;
        margin: 1rem 0 0;
        text-align: center;
        line-height: 1;
        transition: color 0.4s;
        font-weight: 500;

      }
      &.active{
        .circle {
          background-color: $blue_3;
          color: $white;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .estimation-title {
    padding: 3rem 15%;

    .container-img-tilte {
      margin-bottom: 3rem;

      h1 {
        font-size: 3.6rem;
        width: auto;
      }
    }

    .container-step-info {
      max-width: 60rem;
      margin: auto;

      .trait {
        height: 0.2rem;
      }

      .step-menu {
        .circle {
          width: 4rem;
          height: 4rem;
          font-size: 2rem;
        }

        p {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .estimation-title {
    padding: 6rem 20%;

    .container-img-tilte {
      h1 {
        font-size: 4.3rem;
      }

      img {
        max-width: 20rem;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .estimation-title {
    .container-img-tilte {
      h1 {
        font-size: 5rem;
      }
    }
  }
}

.container-step {
  padding: 3rem 5%;

  .step{
    max-width: 65rem;
    margin: 0 auto;
    display: none;
    &.show {
      display: block;
    }
  }

  button {
    cursor: pointer;
  }

  .infos {
    display: flex;
    color: $white;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 1rem;
    padding: 1rem 0;
    margin-bottom: 3rem;

    .info {
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% / 3);
      text-align: center;
      margin: 0.5rem;

      &:nth-child(2) {
        border-left: 1px solid $white;
        border-right: 1px solid $white;
      }

      svg {
        width: 2rem;
        height: 2rem;
        margin-bottom: 1rem;
      }

      p {
        margin: 0;
        padding: 0 1rem;

        .bold {
          font-weight: 700;
        }
      }
    }

  }

  .immat-plaque {
    display: flex;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 3rem auto;
    max-width: 30rem;

    .blue {
      width: 10%;
      background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #3A67B1;
    }

    .immat-input {
      border: none;
      background-color: $white;
      font-weight: 700;
      color: $blue_5;
      text-align: center;
      font-size: 2rem;
      width: 80%;
      border-radius: 0;
      padding: 1.2rem 0;
      text-transform: uppercase;
      margin: 0;

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  .detail-car-info {
    padding: 0;
    max-width: 30rem;
    margin: 3rem auto;
    color: rgba(255, 255, 255, 0.8);
    list-style: none;
    text-transform: uppercase;
    &.center{
      text-align: center;
    }
  }
  .container-input {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: center;
    max-width: 65rem;
    margin: 3rem auto;
  }

  label{
    color: $white;
  }

  input, select {
    margin: 1rem 0;
    width: 100%;
    max-width: 30rem;

    &.center {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .kilometrage-input {
    position: relative;
    display: block;
    margin: 3rem auto;
    max-width: 30rem;

    input {
      padding-right: 4rem;
    }

    &:after {
      content: 'km';
      position: absolute;
      font-size: 1.4rem;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      color: $blue_5;
    }
  }

  .container-next {
    display: flex;
    justify-content: space-between;
    max-width: 30rem;
    margin: 3rem auto 0;
  }
  .container-estimation{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg{
      width: 3rem;
      height: 3rem;
      color: $blue_7;
    }
    .price{
      display: inline-block;
      text-align: center;
      padding: 1rem 2rem;
      background: $linear-gradient-flashy;
      color: $blue_5;
      border-radius: 1rem;
      font-size: 2.4rem;
      min-width: 20rem;
      font-weight: 500;

    }
  }
}

@media screen and (min-width: 768px) {
  .container-step {
    padding: 9rem 10%;

  }
}


