/**
 * Import font-face.
 */
@include font-face('REM', '../fonts/rem/REM', 300 400 500 600 700 800, null, ttf);
@include font-face('Ubuntu', '../fonts/ubuntu/Ubuntu', 300 400 500 700, null, ttf);
@include font-face('Arvo', '../fonts/arvo/Arvo', 400 700, null, ttf);

@font-face {
    font-family: 'Quivera';
    src: url('../fonts/quivera/Quivera-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}