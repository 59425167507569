.actuality-card {
  display: flex;
  flex-direction: column;
  background-color: $white;
  overflow: hidden;
  border-radius: 1rem;
  min-width: 24rem;
  max-width: 26rem;
  box-shadow: 4px 4px 0.8rem $blue_5;
  margin-bottom: 3rem;
  border: 2px solid $white;

  .img-container {
    width: 100%;
    height: 14.5rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  a, h3, p {
    padding-left: 5%;
    padding-right: 5%;
    margin: 0;
  }
  .date {
    font-size: 1.4rem;
    padding-top: 2rem;
    color: $blue_2;
    background-color: $blue_7;
  }
  .actu-title {
    padding-bottom: 2rem;
    background-color: $blue_7;
    min-height: 6rem;
    color: $blue_5;

    h3 {
      font-size: 2rem;
      line-height: 1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 400;
    }
  }
  .resume {
    color: $blue_5;
    padding-top: 2rem;
    padding-bottom: 2rem;

    p {
      // sur 3 lignes
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  a{
    display: inline-block;
    color: $blue_1;
    text-decoration: none;
    font-weight: 800;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    transition: color 0.5s ease-in-out;
    &:hover{
        color: $blue_2;
    }
  }
}

@media screen and (min-width: 1280px) {
  .actuality-card {

    .img-container {
      order: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .date {
      order: 0;
      font-size: 1.4rem;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      color: $white;
      background-color: $blue_5;
    }
    .actu-title {
      order: 2;
      padding-top: 2rem;
      padding-bottom: 2rem;
      background-color: $blue_5;
      color: $white;
      min-height: 10rem;

      h3 {
        -webkit-line-clamp: 3;
      }
    }
    .resume {
      order: 3;
      color: $blue_5;
      padding-top: 2rem;
      padding-bottom: 2rem;

      p {
        -webkit-line-clamp: 3;
      }
    }
    a{
      order: 4;
    }

  }
}