.start {
  background-color: #F2F1F7;
  width: 100%;
  overflow-x: hidden;
  overflow-y: initial;
  padding-bottom: 3rem;


  .top-content {
    .title-part {
      display: flex;
      align-items: center;
      justify-content: center;

      .stars {
        margin: 1.2rem 1rem 0;
        display: inline-flex;

        svg {
          width: 1rem;
          height: 1rem;
          color: $blue_1;
          margin: 0 0.3rem;
        }
      }

      h1 {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;

        .add-background {
          background-color: $blue_5;
          color: $white;
          font-size: 1.6rem;
          padding: 0.4rem 0.5rem;
        }

        .bottom-title {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 1.8rem;
          font-weight: 600;
          text-align: center;
          padding-top: 0.4rem;
          line-height: 1.2;
          width: 100%;


          .strong {
            font-weight: 300;
            letter-spacing: 0.2rem;
            margin-right: -0.2rem;
          }
        }
      }

    }

    .carroussel-car {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 5.5rem;
      height: 8.4rem;

      .cars {
        position: absolute;
        z-index: 1;
        width: 47rem;
        max-width: none;
        display: flex;
        justify-content: center;

        img {
          width: 100%;
        }
      }

      .ground {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 49rem;
        height: 8rem;
        top: 4.4rem;
        border-radius: 100%;
        background: linear-gradient(45deg, #25E5DA 0%, #24FA94 100%);
      }

    }

    .filter-home-page {
      display: flex;
      margin: auto;
      width: 90%;
      max-width: 43rem;
      justify-content: space-between;

      .search-part, .sell-part {
        position: relative;
        height: 33.4rem;

        h3 {
          cursor: pointer;
          font-size: 1.6rem;
          padding: 0.5rem 0 2rem;
          display: block;
          width: 14rem;
          border-top-right-radius: 1rem;
          border-top-left-radius: 1rem;
          text-align: center;
          margin: 0;
          color: $white;
        }

        > div {
          position: absolute;
          width: 90vw;
          max-width: 43rem;
          top: 3.4rem;
          border-radius: 1rem;
          min-height: 30rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          z-index: 1;
          opacity: 0.8;
          transition: z-index 0.5s, opacity 0.3s;
        }

        button {
          cursor: pointer;
          background-color: $blue_6;
          color: $blue_5;
          padding: 1rem 2rem;
          border: 2px solid $white;
          border-radius: 1rem;
          font-size: 1.6rem;
          font-weight: 900;
          text-transform: uppercase;
          transition: color 0.5s, background-color 0.5s;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: italic;
          min-width: 26.5rem;


          svg {
            transition: color 0.5s;
            color: $white;
            margin-right: 1rem;
            height: 1.8rem;
            width: 1.8rem;
          }

          &:hover {
            background-color: $blue_5;
            color: $white;
            border: 2px solid $blue_6;

            svg {
              color: $white;
            }

          }
        }
      }
    }


    .search-part {
      h3 {
        background: linear-gradient(45deg, $blue_5 0%, #13B8B4 1%, #7AE793 100%) top center;
        background-size: 300%;
        transition: background 1s, color 0.5s;
        color: $white;
      }

      &.active {
        h3 {
          background: linear-gradient(90deg, $blue_5 0%, $blue_5 10%, #13B8B4 50%, #7AE793 100%) left center;
          background-size: 800%;
        }

        > div {
          z-index: 2;
          opacity: 1;
        }
      }

      > div {
        left: 0;
        //background: linear-gradient(45deg, #13B8B4 10%, #7AE793 100%);
        padding: 3rem 1.5rem;
        background-color: #192E31;

        form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          > div {
            display: flex;
            flex-flow: row wrap;

            input, select {
              width: 47.5%;
            }

            input:nth-child(2n), select:nth-child(2n) {
              margin-left: 5%;
            }
          }

          select, input {
            padding: 0.5rem 1rem;
            width: 100%;
            margin-bottom: 2rem;
            border: 1px solid $blue_5;
            border-radius: 0.5rem;
            background-color: $white;
            font-size: 1.4rem;

            option:first-of-type {
              color: #999;
            }
          }

        }
      }

    }

    .sell-part {
      h3 {
        background: linear-gradient(45deg, #7AE793 0%, #13B8B4 98%, $blue_5 99%, $blue_5 100%) top center;
        background-size: 200%;
        transition: background 1s, color 0.5s;
          color: $white;

      }

      &.active {
        h3 {
        background: linear-gradient(90deg, #7AE793 0%, #13B8B4 50%, $blue_5 90%, $blue_5 100%) right center;
        background-size: 800%;
        }

        > div {
          z-index: 2;
          opacity: 1;
        }
      }

      > div {
        background-color: $blue_5;
        right: 0;

        .chrono-container {
          display: flex;
          align-items: center;
          margin-bottom: 5rem;

          .chrono-svg {
            width: 5.1rem;
            height: 5.9rem;
            display: flex;
            flex-direction: column;
            margin-right: 1.6rem;
            color: $blue_6;
            display: none;

            .ombre {
              width: 100%;
              height: 1rem;
              background: radial-gradient(50% 50% at 50% 50%, rgba(0, 255, 209, 0.7) 0%, rgba(0, 255, 209, 0.3) 100%);
              margin-top: 0.4rem;
              border-radius: 100%;
            }
          }

          .chrono-text {
            p {
              font-size: 1.6rem;
              font-weight: 800;
              color: $white;
              display: flex;
              flex-direction: column;
              line-height: 1.2;
              text-align: center;
              margin: 0 auto;
              align-items: center;
              width: 60%;

              .number {
                font-size: 4.6rem;
                margin-right: 0.4rem;
                color: $blue_6;
              }

              .d-flex-row {
                display: flex;
                align-items: center;
                text-transform: uppercase;
              }

              .d-flex-col {
                display: flex;
                flex-direction: column;
                font-size: 1.8rem;
                line-height: 1;

                .minutes {
                  color: $blue_6;
                }
              }

              .dot-mark {
                font-size: 4rem;
                margin-left: 0.4rem;
              }
            }
          }
        }
      }
    }
  }

  .explain-part {
    width: 90%;
    margin: 3.2rem auto 0;

    .left-part {
      display: none;
    }

    video {
      display: block;
      width: 100%;
      max-width: 43rem;
      height: auto;
      margin: auto;

    }
  }
}

.video-container {
  position: relative;
  display: none;
  border-radius: 1.3rem;
  overflow: hidden;
  max-width: 43rem;
  margin: auto;

  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &.playing {
    &:after {
      display: none;
    }
  }

  video {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.play-pause {
  &.hide {
    display: none;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: linear-gradient(0deg, #45B9B6 0%, #64D97F 50%, #45B9B6 100%);

  .border-gradient {
    display: block;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    background-color: $white;
    transition: width 0.2s, height 0.2s;
    margin: 0;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-45%, -50%);
    width: 50%;
    height: 50%;
    transition: width 0.5s, height 0.5s;
  }

  &:hover {
    .border-gradient {
      width: 90%;
      height: 90%;
    }
  }

}


@media screen and (min-width: 768px) {
  .start {
    padding-bottom: 6rem;

    .top-content {
      margin-bottom: 3.2rem;

      .carroussel-car {
        height: 15rem;

        .cars {
          width: 67rem;
          height: auto;
        }

        .ground {
          width: 70rem;
          height: 10rem;
          top: 6.8rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .start {
    background: url("../images/background-first-section.jpg") no-repeat center center;
    background-size: cover;

    .top-content {
      display: flex;
      flex-direction: column;

      .carroussel-car {
        order: 3;
        top: -14rem;
        height: 7rem;

        .cars {
          width: 100rem;
          overflow: unset;
          display: block;
        }

        .ground {
          width: 100rem;
          height: 15rem;
          top: 10rem;
        }
      }

      .filter-home-page {
        position: relative;
        z-index: 0;
        max-width: none;
        max-width: 90rem;
        background-color: $blue_5;
        margin-top: 5.3rem;
        padding-bottom: 2rem;

        .search-part, .search-part.active, .sell-part, .sell-part.active, {
          height: auto;
          position: relative;
          top: -5.3rem;

          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 0.2rem;
            height: 20rem;
            border-radius: 0 0 1rem 1rem;
            background: linear-gradient(180deg, #00FFB2 30%, rgba(0, 255, 240, 0) 100%);
            z-index: 1;
          }

          h3 {
            background: linear-gradient(-90deg, #00FFB2 0%, #00FFF0 100%);
            font-size: 2.2rem;
            border-radius: 0;
            width: 100%;
            padding: 1rem 0;
            height: auto;
            color: $blue_5;
            cursor: initial;
          }

          > div {
            height: auto;
            min-height: 35rem;
            position: initial;
            border-radius: 0;
            background: none;
            background-color: $blue_5;
            opacity: 1;
            z-index: 0;
            padding: 3rem;

            form {
              select, input {
                padding: 0.6rem 1.2rem;
                font-size: 1.6rem;
                margin-bottom: 2rem;
              }
            }

            button {
              color: $blue_5;
              background-color: $blue_6;
              border: 2px solid $white;

              svg {
                color: $white;
              }

              &:hover {
                color: $white;
                background-color: $blue_5;
                border: 2px solid $blue_6;
              }

            }


          }
        }

        .sell-part, .sell-part.active {
          &:after {
            left: 0;
          }

          h3 {
            background: linear-gradient(90deg, #00FFB2 0%, #00FFF0 100%);
          }

          .chrono-container {
            //margin-bottom: 10rem;
            margin-bottom: 6rem;

            .chrono-svg {
              display: flex;
              justify-content: center;
              align-items: center;
              color: $blue_6;
              margin-right: 2rem;
              display: none;

              width: 8rem;
              height: 8rem;
              svg {

              }

              .ombre {
                background: radial-gradient(50% 50% at 50% 50%, rgba(0, 255, 209, 0.7) 0%, rgba(0, 255, 209, 0.3) 100%);
              }
            }

            .chrono-text {
              p {
                width: 100%;
                font-size: 2rem;
                .d-flex-col{
                    font-size: 2.4rem;
                }
              }

              .dot-mark, .number {
                font-size: 6rem;
              }

              .number, p .d-flex-col .minutes {
                color: $blue_6;

              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .start {
    .top-content {
      margin-top: 5rem;
      position: relative;
      margin-bottom: 2rem;

      .title-part {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 5;
        left: 50%;
        transform: translateX(-50%);
        top: 11.5rem;

        .stars {
          &:first-of-type {
            display: none;
          }

          margin-top: 0.5rem;

          svg {
            color: $white;
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        h1 {
          margin: 0;

          .add-background {
            background: linear-gradient(45deg, #00FFB2 20%, #00FFF0 100%);
            color: $blue_5;
            font-size: 1.8rem;
            padding: 0.5rem 3rem;
          }

          .bottom-title {
            color: $white;
            font-size: 2.2rem;
          }
        }
      }

      .carroussel-car {
        height: 9rem;
        .cars {
          width: 125rem;
        }

        .ground {
          background: linear-gradient(45deg, #00FFB2 5%, #00FFF0 100%);
          width: 132rem;
          top: 12rem;
          height: 19rem;
        }
      }

      .filter-home-page {
        max-width: 110rem;
      }
    }

    .explain-part {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      max-width: 110rem;
      margin: 6rem auto 0;

      .left-part {
        display: block;
        width: 60%;
        margin-bottom: auto;

        h2 {
          font-size: 4rem;
          color: $blue_5;
          margin-top: 0;
          margin-bottom: 1.6rem;
          text-transform: uppercase;
          display: flex;
          flex-direction: column;
          line-height: 1;
          font-weight: 900;

          .colored {
            color: $blue_7;
          }
        }

        > div {
          width: 100%;
          display: flex;
          //justify-content: space-between;
          align-items: flex-start;

          p {
            display: inline-block;
            width: 80%;
            margin: 0;
          }

          .arrow {
            color: $blue_7;
            width: 8.2rem;
            height: 10.9rem;

            svg {
              width: 100%;
              height: 100%;

              use {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }

      .video-container {
        width: 43rem;
      }
    }
  }

}