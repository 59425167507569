.btn {
  display: block;
  padding: 1rem 2rem;
  text-decoration: none;
  color: $blue_5;
  font-weight: 600;
  font-size: 1.6rem;
  border-radius: 5rem;
  background-color: $blue_7;
  min-width: 10rem;
  transition: color 0.4s, background-color 0.4s;
  line-height: 1.2;
  border: 1px solid $white;
  &.mw-20{
    max-width: 20rem;
  }
  &.white{
    background-color: $white;
    color: $color-text;
    &:hover {
      background-color: $blue_7;
    }
  }

  &:hover {
    background-color: darken($blue_7, 10%);
    color: $color-text;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }
}

.btn {
  display: inline-block;
  &:hover {
    background-color: darken($white, 15%);
  }
}

button {
  cursor: pointer;
}

.prev {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  padding: 1.2rem;
  border-radius: 100%;
  border: 1px solid $white;
  color: $white;

  &:hover {
    border: 1px solid $blue_1;
    color: $blue_1;
  }

  svg {
    transform: rotate(90deg);
  }
}

.link {
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: $white;
  text-align: center;
  font-size: 1.6rem;
  text-decoration: underline;
  border: none;
  margin-top: 2rem;
  transition: color 0.4s;
  &:hover {
    color: darken($white, 15%);
  }
}