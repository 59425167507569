@import "../layout/header-user-profil";

.container-reservation {
  padding: 0 10% 0;
  background-color: $blue_5;
  color: $white;

  .reservation {
    padding: 6rem 0;
  }

  .container-car {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 30.5rem;
    margin: 0 auto 3rem;

    p {
      margin: 1rem 0;
      &.little {
        font-size: 1.2rem;
        margin-top: 0;
      }
      &.white{
        color: $white;
      }
    }

    .car {
      width: 21.5rem;
      height: 13.3rem;
      border-radius: 1rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .reserved {
      font-size: 1.6rem;
      color: $blue_6;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 0;

      span {
        color: $white;
        font-size: 1.2rem;
        line-height: 1;
      }
    }

    .number-reservation {
      width: 100%;

      p {
        margin: 0;
      }

      p:first-of-type {
        background-color: $blue_7;
        color: $blue_5;
        padding: 0.5rem 0;
      }

      p:last-of-type {
        background-color: rgba(255, 255, 255, 0.1);
        color: $white;
        padding: 0.5rem 0;
      }
    }
  }

  .container-text {
    color: rgba(255, 255, 255, 0.6);

    strong {
      color: $white;
    }

    .btn{
      opacity: 0.6;
      color: #fff;
      cursor: pointer;
      background-color: #fff0;
      border: 2px solid #fff;
      border-radius: 5rem;
      margin: 1rem;
      padding: 1rem 2rem;
      font-weight: 700;
      text-decoration: none;
      transition: color .4s, background-color .4s;
      display: inline-block;
      &:hover{
        transition: opacity 0.4s;
        opacity: 1;
      }
    }

  }
}

@media screen and (min-width: 1024px) {
  .container-reservation {
    .reservation {
      display: flex;
      justify-content: space-between;
      max-width: 105rem;
      margin: auto;

      .container-car {
        margin: 0;
      }

      .container-text {
        margin-left: 1.6rem;

        p:first-of-type {
          margin-top: 0;
        }

        width: 75%;
      }
    }

  }
}