/* stylelint-disable at-rule-empty-line-before */
@layer external, theme, pages, utilities;

@import 'tools/functions', 'tools/mixins', 'tools/fonts', 'tools/variables';

@layer theme {
    @import 'reset/sanitize', 'reset/sanitize_forms', 'reset/sanitize_assets';
    @import 'components/title', 'components/icon';
    @import 'layout/base', 'layout/grid';
}

@layer pages {
    @import 'pages/home';
    @import 'pages/occasions';
    @import 'pages/detail-vehicule';
    @import 'pages/simple-text';
    @import 'pages/comparaisons';
    @import 'pages/actualities';
    @import 'pages/actuality';
    @import 'pages/connexion';
    @import 'pages/mes-likes';
    @import 'pages/mes-reservations';
    @import 'pages/mon-profil';
    @import 'pages/reservation-et-offre';
    @import 'pages/valid-offre';
    @import 'pages/estimation-vehicule';
    @import 'pages/estimation-vehicule-valid';
}

@layer utilities {
    @import 'utilities/spacing', 'utilities/visibility';
}
