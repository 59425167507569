@import "../components/paginations";
@import "../components/container-occasions";
@import "../components/filter-occasions";
@import "../components/pop-up-comparaison";

.occasions {
  .top-part {
    background-color: $white;

    .title-part {
      display: flex;
      align-items: center;
      justify-content: center;

      .stars {
        margin: 1.2rem 1rem 0;
        display: inline-flex;

        svg {
          width: 1rem;
          height: 1rem;
          color: $blue_1;
          margin: 0 0.3rem;
        }
      }

      h1 {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;

        .add-background {
          background-color: $blue_5;
          color: $white;
          font-size: 1.6rem;
          padding: 0.4rem 0.5rem;
        }

        .bottom-title {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 1.8rem;
          font-weight: 600;
          text-align: center;
          padding-top: 0.4rem;
          line-height: 1.2;

          .strong {
            font-weight: 300;
            letter-spacing: 0.2rem;
            margin-right: -0.2rem;
          }
        }
      }

    }

    .carroussel-car {
      width: 100%;
      position: relative;
      height: 8rem;

      .cars {
        position: absolute;
        width: 50rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }

      .ground {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 53rem;
        height: 7.5rem;
        top: 4.8rem;
        border-radius: 100%;
        background-color: $blue_7;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .occasions {
    .top-part {
      background-color: $blue_7;
      padding: 6rem 0;

      .title-part {
        h1 {
          margin-top: 0;

          .add-background {
            font-size: 2rem;
          }

          .bottom-title {
            font-size: 2.4rem;
          }
        }

        .stars {
          margin: 0 1rem 1rem;

          svg {
            width: 1.2rem;
            height: 1.2rem;
            color: $white;
          }
        }
      }

      .carroussel-car {
        height: 15rem;

        .cars {
          width: 80rem;
        }
      }
    }
  }
}