.pop-up-offre {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  .pop-up-content{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: $blue_5;
    max-width: 55rem;
    padding: 3rem;
    border-radius: 3rem;
    .btn{
     margin: 3rem 0;
    }
  }
}