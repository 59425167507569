.text {
  font-size: 1.6rem;
  color: $white;
  &.big, .big{
    font-size: 2rem;
  }
  &.center, .center{
    text-align: center;
  }

  &.bold, .bold{
    font-weight: 700;
  }

  .not-bold {
    font-weight: 400;
  }
  &.uppercase, .uppercase{
    text-transform: uppercase;
  }
  &.mt-0, .mt-0{
    margin-top: 0;
  }
  &.mb-0, .mb-0{
    margin-bottom: 0;
  }
  &.little, .little{
    font-size: 1.2rem;
  }
  &.blue, .blue{
    color: $blue_7;
  }
  &.white, .white{
    color: $white;
  }
  &.w-100, .w-100{
    width: 100%;
  }
  &.underline, .underline{
    text-decoration: underline;
  }
}