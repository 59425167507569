@import '../components/title-page-part';
@import '../components/cards/actuality-card';
@import '../components/paginations';


.actualities-container {
  background-color: $blue_5;
  padding: 3rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .actualities-cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .actualities-filter {
    form {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;
    }

    select {
      border-radius: 1rem;
      padding: 0.5rem 2rem;
      background-color: $white;
      border: 1px solid $white;
      color: $blue_5;
      margin-bottom: 1.2rem;

      option {
        color: $blue_5;
        border-radius: 1rem;

        &:hover {
          background-color: $blue_1;
        }
      }
    }

    button {
      cursor: pointer;
      color: $white;
      border-radius: 1rem;
      padding: 0.5rem 2rem;
      background-color: $blue_1;
      border: 2px solid $white;
      margin: 0 1rem 1.2rem;
      transition: background-color 0.4s;

      &:hover {
        background-color: $blue_2;
      }

    }
  }
}

@media screen and (min-width: 768px) {
  .actualities-container {
    padding: 6rem 14%;

    .actualities-filter {
      width: 100%;

      form {
        justify-content: flex-start;
        width: 100%;
      }

      select {
        margin-bottom: 0;
      }

      button {
        margin: 0 1rem 0;
      }
    }

    .actualities-cards {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;

      .actuality-card {
        margin-right: calc(100% - (26rem * 2));

        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .actualities-container {
    padding: 9rem 16%;
    //background-color: $blue_7;

    .actualities-cards {
      .actuality-card {
        width: 26rem;
        margin-right: calc((100% - (26rem * 3)) / 2);

        &:nth-of-type(2n) {
          margin-right: calc((100% - (26rem * 3)) / 2);

        }

        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }

  }
}

@media screen and (min-width: 1630px) {
  .actualities-container {
    .actualities-filter{
      max-width: 130rem;
      margin: auto;
    }
    .actualities-cards {
      max-width: 130rem;
      margin: auto;
      .actuality-card {
        margin-right: calc((100% - (26rem * 4)) / 3);
        &:nth-of-type(2n) {
          margin-right: calc((100% - (26rem * 4)) / 3);
        }
        &:nth-of-type(3n) {
          margin-right: calc((100% - (26rem * 4)) / 3);
        }
        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }
    }
  }
}