.title-page-part{
  background-color: $white;
  &.alternative{
  background: radial-gradient(circle, #fff 50%, #ACC5CD 100%);
  }

  display: flex;
  align-items: center;
  position: relative;
  min-height: 25rem;
  overflow: hidden;
  padding: 0 10%;
  &.lggroupe{
    padding: 3rem 10%;
    align-items: flex-start;
    .img{
      position: absolute;
      bottom: 5%;
      right: 0;
      width: 80%;
      max-width: 25.6rem;
      z-index: 0;
    }
  }
  h1{
    font-family: Arvo, sans-serif;
    position: relative;
    z-index: 1;
    margin: 0;
    font-size: 2.4rem;
    font-weight: 800;
    color: $blue_5;
    filter: drop-shadow(0px 0px 1px #fff);
    line-height: 1.2;
    span{
      font-family: 'Quivera';
      text-transform: uppercase;
    }
    .red{
      color: #e11b29;
    }
  }

  .img{
    position: absolute;
    width: 55vw;
    max-width: 20rem;
    z-index: 0;
    top: 55%;
    transform: translateY(-50%);
    right: 5%;
  }
}

@media screen and (min-width: 764px) {
  .title-page-part{
    min-height: 35rem;
    padding: 0 15%;
    &.lggroupe{
      align-items: center;
        padding: 3rem 15%;
      .img{
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        right: 15%;
        max-width: 20rem;
      }
    }
    h1{
      font-size: 3.6rem;
    }
    .img{
      width: 40vw;
      right: 10%;
    }
  }
}

@media screen and (min-width: 1280px) {
  .title-page-part{
    min-height: unset;
    padding: 6rem 20%;
    justify-content: space-between;
    &.lggroupe{
      padding: 6rem 20%;
      .img{
          position: initial;
        right: 0;
        top: 0;
        transform: none;
        width: 50%;
        max-width: 20rem;
      }
    }
    h1{
      font-size: 4.3rem;
      width: auto;

    }
    .img{
      position: initial;
      transform: rotateY(0);
      width: 50%;
      max-width: 20rem;
    }
  }
}

@media screen and (min-width: 1920px) {
  .title-page-part{
    h1{
      font-size: 5rem;
    }

  }
}