/* stylelint-disable */

/**
 * Restrict sizing to the page width in all browsers (opinionated).
 */
:where(iframe, img, svg, input, video, select, textarea) {
    height: auto;
    max-width: 100%;
}

img{
user-drag: none;
-webkit-user-drag: none;
user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
}
