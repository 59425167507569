.header-user-connected {
  background: radial-gradient(circle, #fff 50%, #ACC5CD 100%);
  padding: 6rem 0 0;

  .hello-user {
    position: relative;
    width: 50%;
    margin: 0 auto;
    padding-top: 1.6rem;
    z-index: 1;

    p {
      font-size: 1.6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1.2;

      span {
        font-weight: 700;
      }
    }

    svg {
      position: absolute;
      top: -3rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      width: 20rem;
      height: 20rem;
    }
  }

  .carroussel-car {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      width: 47rem;
      max-width: none;
    }
  }

  .menu-user {
    margin-top: 3rem;

    ul {
      display: flex;
      list-style: none;
      justify-content: center;
      margin: 0;
      padding: 0;

      li {
        background-color: $blue_7;
        border-radius: 1rem 1rem 0 0;
        margin: 0 0.5rem;
        width: 100%;
        max-width: 16rem;
        text-align: center;

        &.current {
          background-color: $blue_5;

          a, p {
            color: $white;
          }
        }

        a, p {
          margin: 0;
          width: 100%;
          font-size: 1.6rem;
          color: $blue_5;
          text-decoration: none;
          display: block;
          padding: 1rem 0;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 1280px) {
  .header-user-connected {
    padding: 9rem 0 0;

    .hello-user {
      svg {
        width: 20rem;
        height: 20rem;
      }
    }

    .carroussel-car {
      img {
        width: 70rem;
      }
    }

    .menu-user {
      margin-top: 6rem;

      ul {
        display: flex;
        list-style: none;
        justify-content: center;
        margin: 0;
        padding: 0;

        li {
          background-color: $blue_7;
          border-radius: 1rem 1rem 0 0;
          margin: 0 0.5rem;

          &.current {
            background-color: $blue_5;

            a {
              color: $white;
            }
          }

          a {
            font-size: 1.6rem;
            color: $blue_5;
            text-decoration: none;
            display: block;
            padding: 1rem 0.8rem;
          }
        }
      }
    }
  }
}