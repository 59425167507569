footer {
  .footer-rs {
    width: 100%;
    background-color: $blue_7;
    display: flex;
    justify-content: center;
    padding: 1.6rem 0;

    a {
      width: 3rem;
      height: 3rem;
      margin: 0 1rem;
      cursor: pointer;
      &:hover {
        svg {
          color: #000;
        }
      }

      svg {
        transition: color 0.5s;
        width: 100%;
        height: 100%;
        color: $blue_5;
      }
    }
  }

  .footer-text {
    width: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 1.6rem;


    .footer-logo {
      width: 10rem;
      height: 10rem;
      display: block;
      margin-top: 1rem;

      svg {
        width: 100%;
        height: 100%;
      }

      use {
        color: $blue_5;
      }
    }

    .footer-mentions {
      margin-top: 1rem;
    }

    p {
      font-size: 1.4rem;
      margin-top: 1rem;

      a {
        color: $blue_5;
        text-decoration: none;
        transition: color 0.5s;

        &:hover {
          color: $blue_1;
        }

      }
    }
    .footer-copy{
      color: $blue_1;
    }
  }
}