@import './../components/form-input';
@import "../utilities/_text.scss";
@import "../components/_button.scss";
@import "../components/pop-up-offre";


.title-reservation {
  background: radial-gradient(circle, #fff 50%, #ACC5CD 100%);
  padding: 3rem 5%;

  .top-title {
    display: flex;
    position: relative;

    h1 {
      position: relative;
      z-index: 2;
      font-family: Arvo, sans-serif;
      font-size: 2.2rem;
      font-weight: 700;
      display: flex;
      flex-direction: column;

      span {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }

    img {
      position: absolute;
      top: -1rem;
      right: 0;
      width: 16rem;
    }
  }

  .bottom-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
    max-width: 30rem;


    .btn-fiche {
      display: inline-flex;
      align-items: center;
      line-height: 1;
      text-decoration: none;
      color: $white;
      background-color: $blue_7;
      border-radius: 1rem;
      padding: 1rem;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 700;
      transition: background-color 0.5s;

      svg {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;
      }

      &:hover {
        background-color: $blue_1;
      }
    }

    .price {
      margin: 0;
      color: $white;
      background-color: $blue_5;
      padding: 0.8rem 1rem 0.8rem 1.5rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      .number{
      font-size: 1.8rem;
      font-weight: 700;
        display: flex;
      }

      span {
        font-size: 1.2rem;
        &.ttc{
        margin-left: 0.5rem;
        }
        font-weight: 300;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .title-reservation {
    padding: 6rem 15%;

    .top-title {
      h1 {
        font-size: 3.6rem;

        span {
          font-size: 2rem;
        }
      }

      img {
        top: 0rem;
        width: 24.5rem;
      }
    }

    .bottom-title {
      width: 60%;
      max-width: 40rem;

      .btn-fiche {
        padding: 1rem;
        font-size: 1.6rem;
      }

      .price {
        padding: 0.8rem 1.5rem 0.8rem 2rem;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .title-reservation {
    padding: 6rem 20%;

    .top-title {
      h1 {
        font-size: 4.3rem;

        span {
          font-size: 2.4rem;
        }
      }

      img {
        width: 30rem;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .title-reservation {
    .top-title {
      img {
        width: 35rem;
      }
    }
  }

}

// --- Reservation Section ---
.reservation-section, .offre-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;


  .container {
    flex: 1;
    padding: 6rem 10%;
    background-color: $blue_5;
    color: $white;

    .step {
      max-width: 65rem;
      margin: 0 auto;
      display: none;
      img {
        max-width: 15rem;
      }

      &.show {
        display: block;
      }

      &.valid{
        text-align: center;

        .container-next {
          justify-content: center;
        }
      }
      &.center{
        text-align: center;
      }

      .card {
        align-items: center;
      }

      .legend {
        color: $blue_5;
        background-color: $white;
        border: solid 1px $blue_1;
        border-radius: 1rem;
        overflow: hidden;
        margin-left: 3.1rem;

        .row {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $blue_1;

          &:last-of-type {
            border-bottom: none;
          }

          p {
            margin: 0;
            font-size: 1.2rem;
          }

          p:first-of-type {
            border-right: 1px solid $blue_1;
            min-width: 16.5rem;
            flex: 1;
            padding: 0.5rem 1rem 0.5rem 2rem;
          }

          p:last-of-type {
            background-color: $blue_7;
            align-self: stretch;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 6rem;
          }
        }
      }


    }

    .container-input {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      align-items: center;
      max-width: 65rem;
    }

    .offre-input {
      position: relative;
      display: inline-block;

      input {
        text-align: right;
        padding-right: 3rem;
      }

      &:after {
        content: '€';
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: $blue_5;
        font-size: 1.6rem;
      }
    }

    input , select{
      margin: 1rem 0;
      width: 100%;
      max-width: 30rem;
    }

    .container-next {
      display: flex;
      justify-content: space-between;
      max-width: 30rem;
      margin: 3rem auto 0;

      button {
        cursor: pointer;
      }
    }

  }
}
