@import '../components/title-page-part';

.connexion-section {

  min-height: calc(100vh - (108px + 278px));
  display: flex;
  flex-direction: column;
  .container {
    background-color: $blue_5;
    padding: 6rem 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $blue_5;
      p, a{
        color: $white;
      }

      .container-input {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        max-width: 60rem;
        &.center{
          justify-content: center;
        }
        > div {
          width: 100%;
          max-width: 26rem;
          margin: 0 1rem 1.8rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        input {
          width: 100%;
          border-radius: 1rem;
          background-color: $white;
          padding: 0.6rem 1.4rem;
          font-size: 1.4rem;
          margin: 0 auto;
        }
      }

      button {
        color: #192e31;
        cursor: pointer;
        background-color: #fff;
        border: none;
        border-radius: 5rem;
        padding: 0.5rem 3rem;
        font-size: 1.6rem;
        transition: background-color .4s, color .4s;
        margin: 1.2rem 0 3rem;

        &:hover {
          background-color: darken(#fff, 10%);
        }
      }
    }

    a {
      cursor: pointer;
      color: $white;
      font-size: 1.6rem;
      text-decoration: none;
    }
  }
}

@media screen and (min-width: 768px){
  .connexion-section {
    .container {
      padding: 9rem 10%;

      form {
        .container-input {
          justify-content: space-between;
          max-width: 55rem;

          > div {
            margin: 0 0rem 1.8rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px){
  .connexion-section {
    .container {
      padding: 9rem 20%;
    }
  }
}