/* stylelint-disable */

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Add typography inheritance in all browsers (opinionated).
 */
:where(button, input, select, textarea) {
    background-color: transparent; /* 1 */
    border: 1px solid WindowFrame; /* 1 */
    color: inherit; /* 1 */
    font: inherit; /* 2 */
    letter-spacing: inherit; /* 2 */
    padding: 0.25em 0.375em; /* 1 */
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */
:where(select) {
    -webkit-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E")
        no-repeat right center / 1em;
    border-radius: 0;
    padding-right: 1em;
}

/**
 * Don't show the arrow for multiple choice selects
 */
:where(select[multiple]) {
    background-image: none;
}

/**
 * Remove the border and padding in all browsers (opinionated).
 */
:where([type='color' i], [type='range' i]) {
    border-width: 0;
    padding: 0;
}
