/* stylelint-disable scss/dollar-variable-empty-line-before */

/* Colors */
$color-primary-contrast: #fff !default;

$white : rgba(255, 255, 255, 1) !default;
$opacity : rgba(255, 255, 255, 0) !default;

// le bleu
$blue_1: #4BBAB4 !default;
$blue_2: #347975 !default;
$blue_3: #005E58 !default;
$blue_4: #11434E !default;
$blue_5: #192E31 !default;
$blue_6: rgb(0, 255, 209) !default;
$blue_7: #1CE6C2 !default;
$linear-gradient-primary : linear-gradient(45deg, #45B9B6 0%, #64D97F 100%) !default;
$linear-gradient-flashy : linear-gradient(84deg, #00FFB2 17%, #00FFF0 98%) !default;
$linear-gradient-secondary : linear-gradient(90deg, #13B8B4 9.9%, #7AE793 100%) !default;
$linear-gradient-dark : linear-gradient(180deg, #386C6A 5.73%, #347975 19.79%) !default;
$radial-gradient-dark : radial-gradient(50% 50% at 50% 50%, #4399A5 0%, #192E31 100%);

// drop shadow
$drop-shadow : drop-shadow(2px 2px 4px #87A3AF);
$shadow2 : drop-shadow(0px 0px 6px #192E31);





$color-text: #2e2c34 !default;
$color-background: #fff !default;

/* Typography */
$font-family-text: 'Manrope', sans-serif !default;
$font-size-text: 16px !default;
$font-weight-text: 400 !default;
$font-line-height-text: 1.5 !default;

$font-sizes-title: (
    h1: (
        base: 2.4rem,
        // Base font size for h1 in rem
        md: 3.2rem,
        // Font size for h1 from md breakpoint in rem
    ),
    h2: (
        base: 2rem,
        md: 2.8rem
    ),
    h3: (
        base: 1.6rem,
        md: 2rem
    ),
    h4: (
        base: 1.6rem
    ),
    h5: (
        base: 1.6rem
    ),
    h6: (
        base: 1.6rem
    )
) !default;

/* Breakpoints */
$breakpoints: (
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1536px
) !default;

/* Spacings */
$spacing-multiplier: 8 !default; // Multiplier for spacing (in pixels)
$conversion-divisor-rem: 10 !default; // Divisor for conversion to rem (10 if html { font-size: 62.5% })
