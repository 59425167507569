.offer-send-section {
  padding: 0 10% 0;
  background-color: $blue_5;
  color: $white;

  p {
    margin: 1rem 0;
  }

  .offre {
    padding: 6rem 0;
    max-width: 115rem;
    margin: auto;

    .container-car {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0 auto 3rem;

      .car-img {
        width: 21.5rem;
        height: 13.3rem;
        border-radius: 1rem;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .car-info-valid {

        .offre-valide {
          font-size: 2rem;
          color: $blue_6;
          text-transform: uppercase;
          font-weight: 700;
          margin-bottom: 0;
        }
        p{
          max-width: 35rem;
          margin: 1.2rem auto;
        }

        .number-offre {
          width: 100%;
          max-width: 21.5rem;
          margin: auto;

          p {
            margin: 0;
          }

          p:first-of-type {
            background-color: $blue_7;
            color: $blue_5;
            padding: 0.5rem 0;
          }

          p:last-of-type {
            background-color: rgba(255, 255, 255, 0.1);
            color: $white;
            padding: 0.5rem 0;
          }
        }
      }
    }


    .container-text {
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      margin: 0 auto;
      max-width: 45rem;

      strong {
        color: $white;
      }
    }

  }
}

@media screen and (min-width: 768px) {
  .offer-send-section {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .offre {
      .container-car {
        .car-img {
          width: 30rem;
          height: 23rem;
        }
        .car-info-valid {
          .offre-valide {
            font-size: 2rem;
            margin-top: 3rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px){
  .offer-send-section {
    .offre {
      .container-car {
        flex-flow: row wrap;
      }
    }
  }

}