.table-container {
  width: 90%;
  margin: 0 auto;
  padding: 3rem 0 0;

  &.first {
    padding-top: 0;
  }

  @media screen and (min-width: 1024px) {
    width: 80%;
  }
}

.comparaisons-section {
  background-color: $blue_5;
  padding: 3rem 0;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 3rem;

    h1 {
      display: block;
      font-size: 2rem;
      font-weight: 800;
      color: $blue_6;
      margin: 0;
      text-transform: uppercase;
    }

    .cross {
      width: 2rem;
      height: 2rem;
      color: $blue_6;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .sticky {
    width: 90%;
    margin: 0 auto;
    top: 50rem;
    background-color: $blue_5;
    position: sticky;
    top: 0;
    @media screen and (min-width: 1024px) {
      width: 80%;
    }

    .container-car {
      display: flex;
    }

    .empty {
      flex: 1;
      margin: 0 0.4rem;

    }

    .car {
      flex: 1;
      background-color: $white;
      margin: 0 0.4rem;
      border-radius: 1rem 1rem 0 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;


      .model {
        font-size: 1.2rem;
        font-weight: 900;
        font-style: italic;
        color: $blue_5;
        margin: 1rem 0 auto;
        line-height: 1;
        // 2 ligne maximum
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media screen and (min-width: 768px) {
          font-size: 1.8rem;
          margin: 1rem 10% auto;
        }

        @media screen and (min-width: 1280px){
            font-size: 2rem;

        }
        @media screen and (min-width: 1920px){
            font-size: 2.4rem;
        }
      }

      p {
        font-size: 1.2rem;
        font-weight: 700;
        color: $blue_1;
        margin: 1rem 0.5rem;
        line-height: 1;
        // 2 ligne maximum
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media screen and (min-width: 768px) {
          font-size: 1.6rem;
          margin: 1rem 10% 2rem;
        }
        @media screen and (min-width: 1280px){
            font-size: 1.8rem;
        }
        @media screen and (min-width: 1920px){
            font-size: 2rem;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .comparaisons-section {
    .title {
      h1 {
        font-size: 3.2rem;
      }

      .cross {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .comparaisons-section {
    padding: 6rem 0;

    .title {
      h1 {
        font-size: 4.3rem;
      }
    }
  }
}

.table-start {
  width: 100%;
  padding: 0 5%;
  margin: 0.2rem auto 0;
  background-color: #000;
  @media screen and (min-width: 1024px) {
    padding: 0 10%;

  }

  .table {
    border: 0;

    p {
      border: 0;
      background: none;
    }
    >div{
      border-right: 1px solid rgba(0, 255, 209, 0.3);
    }
    .data-col {
      p {
        background: none;
      }
    }
  }
}

.table-end {
  padding-top: 3rem;
  display: flex;
  width: 90%;
  margin: auto;
  @media screen and (min-width: 1024px) {
    width: 80%;
  }

  .data-col {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    @media screen and (min-width: 1280px) {
      flex-flow: row wrap;
    }

    > div {
      display: flex;
      justify-content: center;
      min-width: 6.4rem;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1;
        color: $blue_6;
        border: 1px solid $blue_6;
        border-radius: 1rem;
        width: 6.4rem;
        height: 4.8rem;
        margin: 0.5rem;
        font-size: 1.2rem;
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;


        span {
          font-size: 1.6rem;
          line-height: 0.7;
        }

        svg {
          width: 1.6rem;
          height: 1.6rem;
        }

        &:hover {
          background-color: $blue_6;
          color: $blue_5;

        }
      }

      &.more {
        a {
          background-color: $blue_6;
          color: $blue_5;

          &:hover {
            background-color: $blue_5;
            color: $blue_6;
          }
        }
      }

      &.like {
        svg {
          fill: $blue_5;
          transition: fill 0.3s ease-in-out;

          path {
            stroke: $blue_6;
          }
        }

        &.active {
          svg {
            fill: $blue_6;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .table-end {
    .data-col {
      > div {
        a {
          font-size: 1.4rem;
          width: 7.2rem;
          height: 5.5rem;
          span {
            font-size: 2rem;
          }
          svg{
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }
}


.table {
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  line-height: 1;
  border-top: 1px solid $blue_6;


  svg {
    width: 2rem;
    height: 2rem;

    &.missed {
      color: darken($blue_7, 30%);
    }
  }

  p {
    margin: 0;
    height: 4rem;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(28, 230, 194, 0.30);
  }

  .title-col {
    color: $white;
    flex: 1;
    text-align: left;

    p {
      padding-right: 0.5rem;
      word-break: break-word;
    }
  }

  .data-col {
    flex: 1;
    text-align: center;

    p {
      color: $blue_7;
      background: linear-gradient(90deg, rgba(0, 255, 209, 0.1) 0%, rgba(0, 255, 209, 0) 80%);
      justify-content: center;
      padding: 0 0.5rem;
    }
  }
}
.table-name {
  font-size: 1.8rem;
  color: $blue_7;
  font-weight: 400;
  margin: 0 0 1.2rem;
}

@media screen and (min-width: 768px) {
  .table {
    font-size: 1.6rem;
  }
  .table-name {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1920px) {
  .table {
    font-size: 1.8rem;
  }
  .table-name {
    font-size: 3.2rem;
  }
}