
.filtersPart {
  padding: 8rem 0 3rem;
  background-color: $blue_5;

  &.open {
    padding: 0;
  }


}

.filters {
  background: $blue_7;
  color: $blue_5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
  border-radius: 2rem;
  padding: 2rem;
  transition: width 0.5s;
  max-width: 34.2rem;
  position: relative;

  .activate-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  .filter {
    .pop-up-filtre {
      display: none;
      color: $white;
      position: fixed;
      cursor: initial;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.20);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999999999999;
      border-radius: 0.5rem;

      .pop-up-content {
        width: 95%;
        height: 80vh;
        max-width: 40rem;
        border-radius: 0.5rem;
        background-color: #324447;
        overflow: hidden;

      }

      &.open {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .navigation {
        margin-top: 3rem;
        margin-bottom: 3rem;
        width: 100%;
        display: flex;
        width: 100%;
        justify-content: space-around;
        font-weight: 300;

        .cross {
          display: inline-flex;
          align-items: center;
          cursor: pointer;

          p {
            margin: 0;
            font-size: 1.6rem;
            color: $white;
          }

          svg {
            display: block;
            color: $white;
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
          }
        }

        button {
          cursor: pointer;
          display: flex;
          align-items: center;
          border: 1px solid $white;
          border-radius: 0.5rem;
          background-color: $blue_6;
          padding: 0.5rem 1rem;
          color: $blue_5;
          transition: color 0.3s, background-color 0.3s;

          svg {
            color: $blue_5;
            margin-left: 1.5rem;
            width: 1.6rem;
            height: 1.6rem;
            transition: color 0.3s;
          }

          &:hover {
            border: 1px solid $blue_6;
            background-color: $white;
            color: $blue_5;

            svg {
              color: $blue_5;
            }
          }
        }
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        h4 {
          display: block;
          color: $white;
          margin: 0;
          font-size: 2.2rem !important;
        }


        svg {
          color: $blue_6;
          margin-left: 1rem;
          width: 3rem;
          height: 3rem;
        }
      }

      .choice {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: auto;
        height: calc(80vh - 14.4rem);

        li {
          background-color: $blue_5;
          border-top: 1px solid rgba(255, 255, 255, 0.3);
          padding: 1.5rem 3rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.6rem;
          cursor: pointer;
          transition: 0.4s background-color;

          &:hover {
            background-color: darken($blue_5, 5%);
          }

          p {
            margin: 0;
            width: 80%;
            text-transform: capitalize;
          }

          svg {
            width: 2rem;
            height: 2rem;
            color: rgba(255, 255, 255, 0);
          }

          &.selected {
            svg {
              color: $blue_6;
            }
          }
        }

        label {
          display: block;
          font-size: 1.6rem;
          width: 23rem;
          margin: 0 auto 0.5rem;
        }

        input {
          display: block;
          width: 23rem;
          margin: 0 auto 1.6rem;
          border: 1px solid $blue_6;
          border-radius: 0.5rem;

          &:focus-visible, &:focus {
          }

        }

      }

    }
  }

  .title-filters {
    border-bottom: 1px solid $blue_5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 50rem;
    padding: 1rem 0;

    h3 {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 300;
    }

    svg {
      display: none;
      margin-left: auto;
      height: 0rem;
      width: 0rem;
    }
  }

  .filter-card {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1.6rem 0;
    max-width: 50rem;

    .filter {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 1rem 0.5rem;
      border-radius: 0.4rem;
      cursor: pointer;
      overflow: hidden;
      width: 3.5rem;
      height: 3.5rem;
      background-color: rgba(255, 255, 255, 0);

      .incon-and-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;
        padding: 0;
      }

      h4 {
        display: none;
        font-size: 1.6rem;
        font-weight: 500;
        margin: 0.5rem 0 0;
        color: $white;
        letter-spacing: -0.5;
        line-height: 1;
      }

      .selected-filter {
        display: none;
      }

      svg {
        color: $blue_5;
      }

      &.active {
        svg {
          color: $white;
        }
      }


    }
  }

  .container-filtres {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .carsNumber {
      font-size: 1.4rem;
      text-align: center;

    }

    .container-btn {
      display: none;

      button {
        border: none;
        display: inline-block;
        font-size: 1.6rem;
        background-color: $white;
        border-radius: 5rem;
        padding: 0.5rem 3rem;
        color: $blue_5;
        transition: background-color 0.4s, color 0.4s;
        cursor: pointer;
        margin-right: 1rem;

        span {
          color: $blue_6;
        }

        &:hover {
          background-color: darken($white, 5%);
        }
      }
    }
  }

  &.open {
    width: 100%;
    max-width: none;
    border-radius: 0;
    padding: 8rem 0 3rem;

    .activate-filter {
      display: none;
    }

    .title-filters {
      svg {
        display: block;
        height: 1.4rem;
        width: 1.4rem;
      }
    }

    .filter-card {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      .filter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1rem 0.5rem;
        width: 10rem;
        height: 10rem;
        border-radius: 0.4rem;
        background-color: #192E31;
        cursor: pointer;
        overflow: hidden;

        .card-filter {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .incon-and-title {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-bottom: 1rem;
        }

        svg {
          width: 3.5rem;
          height: 3.5rem;
          color: $blue_6;
        }

        h4 {
          display: block;
          font-size: 1.6rem;
          font-weight: 500;
          margin: 0.5rem 0 0;
          color: $white;
          letter-spacing: -0.5;
          line-height: 1;
        }

        .selected-filter {
          margin: 0;
          padding: 0 0.5rem;
          font-size: 1.4rem;
          line-height: 1.1;
          text-align: center;
          color: $blue_1;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          background-color: white;
          height: 0;
          width: 100%;
          font-weight: 300;
        }

        &.active {
          background-color: $white;

          .incon-and-title {
            margin-top: auto;
          }

          h4 {
            color: $blue_5;
            margin-top: 0;
          }

          .pop-up-filtre {
            h4 {
              color: $white;
              margin: 0;
              letter-spacing: initial;
              font-size: 2.2rem;

            }
          }


          .selected-filter {
            padding: 0.4rem 0.5rem;
            height: calc(1.4rem * 2 + 0.8rem);
          }

        }
      }


    }

    .container-filtres {
      display: flex;
    }

    .carsNumber {
      display: none;
    }

    .container-filtres {
      .container-btn {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        button {
        margin-top: 2rem;
          display: block;
        }
      }
    }
  }

}

@media screen and (min-width: 1280px) {
  .filtersPart {
    background: none;
  }
  .filters {
    background: none;

    .container-filtres {
      > button {
        padding: 1rem 10rem;

        span {
          color: $blue_1;
        }
      }
    }

    &.open {
      padding-bottom: 0rem;

      .title-filters {
        h3 {
          font-size: 2.8rem;
        }

        svg {
          display: none;
        }
      }

      .filter-card {
        max-width: 100rem;

        .filter {
          width: 14rem;
          height: 10.5rem;

          &.active {
            background-color: $blue_5;

            .incon-and-title {
              display: flex;
              flex-direction: row;
              padding-top: 1rem;

              svg {
                margin-right: 0.5rem;
                width: 2.5rem;
                height: 2.5rem;
              }

              h4 {
                color: $white;
              }
            }

            .selected-filter {
              text-align: center;
              color: $blue_5;
              height: 100%;
              font-size: 1.5rem;
              line-height: 1.2;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
    }

  }
}