@import "../layout/header-user-profil";

.container-profil {
  padding: 6rem 10%;
  background-color: $blue_5;
  color: $white;

  .container-data {
    max-width: 40rem;
    margin: auto;

    .data {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 1.4rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      p {
        margin: 1rem 0;
      }

      .blue {
        color: $blue_6;
      }
      .t-align-right{
        text-align: right;
        margin-left: 1rem;
      }
    }
  }

  .container-btn {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
  }
}

.btn-primary {
  padding: 1rem 2rem;
  border-radius: 5rem;
  background-color: $blue_7;
  text-decoration: none;
  display: inline-block;
  border: 2px solid $white;
  color: $blue_5;
  font-weight: 700;
  margin: 1rem 0.5rem;
  cursor: pointer;
  transition: color 0.4s, background-color 0.4s;
  text-align: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0);
    color: $white;
  }
}

.btn-secondary {
  padding: 1rem 2rem;
  border-radius: 5rem;
  background-color: rgba(255, 255, 255, 0);
  text-decoration: none;
  display: inline-block;
  border: 2px solid $white;
  color: $white;
  font-weight: 700;
  margin: 1rem;
  cursor: pointer;
  transition: color 0.4s, background-color 0.4s;

  &:hover {
    background-color: $blue_7;
    color: $blue_5;

  }
}