.info-dot {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.4rem !important;
    height: 1.4rem !important;
    margin: 0 !important;
    margin-left: .5rem;
    color: $white !important;
  }

  &:hover {
    color: darken($white, 20%) !important;

    .tooltip {
      display: block;
    }
  }

  .tooltip {
    display: none;
    //display: block;
    background-color: $color-text;
    color: white;
    position: absolute;
    border-radius: 0.5rem;
    border: 0.1rem solid $white;
    bottom: 50%;
    right: -3rem;
    font-size: 1.2rem!important;
    font-weight: normal!important;
    padding: 0.5rem;
    width: 15rem;
    z-index: 100;

  }

}

