.estimation-confirm {
  padding: 3rem 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;

  svg {
    width: 20rem;
    height: 20rem;
    color: $blue_7;
  }

  p:first-of-type {
    font-size: 2.4rem;
    color: $blue_7;
    text-transform: uppercase;
    font-weight: 700;
    margin: 2rem 0;
  }

  p:last-of-type {
    font-size: 1.6rem;
    text-align: center;
    margin: 2rem 0;
  }
}

@media screen and (min-width: 768px) {
  .estimation-confirm {
    padding: 9rem 15%;

    svg {
      width: 25rem;
      height: 25rem;
    }
  }
}

@media screen and (min-width: 1280px) {
  .estimation-confirm {
    padding: 9rem 20%;

    p:first-of-type {
      font-size: 3.2rem;
    }

    p:last-of-type {
      font-size: 1.8rem;
    }
  }
}

@media screen and (min-width: 1920px) {
  .estimation-confirm {
    padding: 9rem 20%;
    min-height: calc(100vh - 16.4rem - 27.8rem);
    svg{
      margin-bottom: 3rem;
    }

    p:first-of-type {
      font-size: 4rem;

    }

    p:last-of-type {
      font-size: 2rem;
    }
  }
}