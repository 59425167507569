@import "./../components/_form-input";

.pop-up {
  display: none;

  button {
    cursor: pointer;

    svg {
      pointer-events: none;
    }
  }

  &.open {
    display: flex;
  }

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  justify-content: center;
  z-index: 9999999999;
  overflow: auto;


  .content-form {
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 95%;
    border-radius: 3rem;
    background-color: $blue_5;
    color: $white;
    padding: 3rem 5%;
    position: relative;
    margin: auto;

    .title {
      display: block;
      margin-bottom: 3rem;

      h3 {
        width: 80%;
        margin: 0;
        font-size: 1.8rem;
        text-align: center;
        margin: 0 auto;
      }

      button {
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      textarea {
        width: 100%;
        margin-bottom: 1rem;
      }

      .rgpd {
        font-size: 1.2rem;
      }
    }

    .form-group {
      input {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

  }
}

@media (min-width: 768px) {
  .pop-up {
    .content-form {
      max-width: 50rem;
      padding: 3rem 3rem;

      .form-group {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        input {
          width: 48%;
        }
      }
    }
  }
}

.section-detail {
  background-color: $blue_5;
  color: $white;

  .container-detail {

    padding: 3rem 5%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .container-visual-car {
    width: 100%;
    position: relative;
    max-width: 54.3rem;
    margin: auto;

    .img-principal {
      position: relative;
      display: block;
      width: 100%;
      height: 41rem;
      overflow: hidden;
      background-color: #000;
      border-radius: 1rem;
      margin-bottom: 1rem;
      .slider-img-car{
        &.hidden{
            display: none;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      .like {
        position: absolute;
        cursor: pointer;
        top: 1rem;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        color: white;

        svg {
          path {
            stroke: $blue_1;
          }
        }
      }
    }


    .swiper {


      .swiper-wrapper {

        .swiper-slide {
          height: 7rem !important;
          cursor: pointer;

          img {
            border-radius: 0.5rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

    }
  }

  .dataPrincipal {
    width: 100%;
    max-width: 53.4rem;
    margin: auto;
  }

  .title-car {
    width: 90%;
    margin: 2rem auto;

    h1 {
      font-size: 2rem;
      margin: 0;
      text-transform: uppercase;
    }

    p {
      color: $blue_7;
      font-size: 1.6rem;
      margin: 0;
      font-weight: 600;
    }
  }


}

.price-part {
  display: flex;
  flex-flow: column;
  max-width: 53.4rem;
  margin: auto;

  .container-priceInfo {
    flex: 1;

    .price-info {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 1rem;
      margin: 1rem 0;

      .financement {
        width: 65%;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 70%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.5);

        }
      }

      .achat {
        width: 45%;
      }

      .financement, .achat {
        position: relative;
        padding: 1rem 1.2rem;

        text-align: center;

        h2 {
          margin-top: 0;
          font-size: 1.6rem;
          color: $blue_7;
          font-weight: 700;
        }

        p {
          margin-bottom: 0;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          font-size: 1.4rem;
          line-height: 1;
          margin: 1rem auto;

          span {
            margin: 0 0.4rem;
            font-weight: 700;
            font-size: 2rem;
          }

          &.priceAchat {
            font-weight: 700;
            font-size: 1.7rem;
            @media screen and (min-width: 768px) {
              font-size: 2.2rem;
            }
          }

          svg {
            margin-left: 0.2rem;
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }

    .action-btn {
      .btn-primary, .btn-secondary {
        text-align: center;
        border-radius: 1rem;
        width: 100%;
        margin: 0 0 1rem;
      }

    }
  }

  .container-form-contact {
    display: flex;
    justify-content: space-around;

    > div {
      width: 10rem;
      height: 8rem;
      margin: 0 0.2rem;

    }

    .btn-action {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      color: $white;
      width: 100%;

      line-height: 1;
      font-size: 1.2rem;
      padding: 1rem 0.5rem;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 1rem;
      border: 1px solid rgba(255, 255, 255, 0.3);
      cursor: pointer;
      transition: background-color 0.4s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }

      svg {
        width: 2rem;
        height: 2rem;
        margin-bottom: 0.4rem;
        color: $blue_7;
        pointer-events: none;

      }

    }

  }

}

.characteristic {
  margin: 3rem auto;

  h2 {
    font-size: 1.8rem;
    margin: 0;
  }

  .containerItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 10rem;
      margin: 1rem 0;

      svg {
        width: 4rem;
        height: 4rem;
        margin-bottom: 1rem;
        color: $blue_7;

      }

      p {
        margin: 0;
        font-size: 1.4rem;
      }
    }
  }
}

.container-cars-info {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  max-width: 53.4rem;
  margin: auto;
  justify-content: center;
}

.car-info {
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
  margin: 1rem 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;

  &.equipment {
    .content-data {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: calc(100% / 2);
          padding: 1rem 2rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          border-right: 1px solid rgba(0, 0, 0, 0.3);
        }

      }
    }

  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $blue_7;
    position: relative;
    padding: 1rem 2rem;
    color: $blue_5;
    cursor: pointer;

    h3 {
      font-size: 1.8rem;
      font-weight: 500;
      margin: 0;
      pointer-events: none;


    }

    svg {
      pointer-events: none;
      width: 2rem;
      height: 2rem;
      transition: transform 0.4s;
      transform: rotate(0deg);
    }
  }

  &.active {
    .title {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .content-data {
    transition: height 0.7s;
    height: 0;
    overflow: hidden;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      background-color: $white;
      color: $blue_5;

      li {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 1rem 0;

        p {
          margin: 0;
          width: 50%;
          padding-left: 1rem;
          padding-right: 0.5rem;
        }

        &.sub-title {
          font-weight: 500;
          font-size: 1.6rem;
          background-color: #8C9698;
          color: $white;

          p {
            width: 100%;
          }

        }

      }
    }
  }
}

@media screen and (min-width: 768px) {
  .section-detail {
    .container-detail {
      padding: 6rem 10%;
    }
  }
  .price-part {
    display: flex;
    flex-flow: row;
    width: 100%;
    margin: auto;

    .container-priceInfo {
      .price-info {
        margin-top: 0;
      }

      .action-btn {
        .btn-primary {
          &:last-of-type {
            margin: 0;
          }
        }
      }
    }

    .container-form-contact {
      flex-direction: column;
      justify-content: space-between;

      > div {
        margin: 0 0 0.5rem 1rem;
        width: auto;
        height: 6rem;

        &:last-of-type {
          margin: 0 0 0 1rem;
        }
      }

      .btn-action {
        flex-direction: row;
        text-align: left;
        line-height: 1;
        font-size: 1.2rem;
        padding: 1rem 1rem;
        height: 100%;

        svg {
          width: 2.5rem;
          height: 2.5rem;
          margin: 0 1rem 0 0;
        }

      }

    }

  }
}

@media screen and (min-width: 1440px) {
  .section-detail {
    .container-detail{
    flex-direction: row;
    padding: 6rem 0;
      max-width: 115rem;
      margin: auto;
    }

    .dataPrincipal {
      margin-top: 0;
    }

    .title-car {
      margin-top: 0;
    }

  }
  .container-cars-info {
    margin-top: 3rem;
    width: 100%;
    max-width: none;
    display: flex;
    justify-content: space-between;
    .car-info{
     width: 45%;
      &.equipment{
        width: 100%;
        .content-data{
          ul{
            li{
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
}


