// à delete
.all-occasions {
  .container-car-card {
    &:nth-of-type(2n + 3) {
      .offer-container {
        display: none;
      }
    }
  }
}

.avantages {
  background-color: $white;
  padding: 3.2rem 10%;

  .title-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(45deg, #25E5DA 0%, #24FA94 100%);
    height: 0;
    transition: 0.7s;

    &.open {
      height: 36.8rem;
    }

    h2 {
      margin: 0;
      margin-top: 7.5rem;
      color: $blue_5;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: 900;
      text-align: center;
      line-height: 1.1;
      font-size: 2rem;

      .circle {
        width: 5.2rem;
        height: 5.2rem;
        border-radius: 5.2rem;
        background-color: $blue_5;
        font-size: 1.3rem;
        color: $blue_6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 0.8;
        padding-top: 0.3rem;

        span {
          color: $white;
          font-size: 4.2rem;
          line-height: 0.8;
        }
      }

      .white {
        color: $white;
        background-color: $blue_5;
        padding: 0.3rem 0.5rem;
        margin: 0.5rem 0;
      }
    }

    .arrow {
      color: $white;
      height: 9rem;
      margin: 3rem auto;

    }
  }

  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-weight: 900;
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 20%, rgba(172, 197, 205, 1) 100%);
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: red;
    }

    h3 {
      min-width: 17rem;
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
      color: $white;
      font-weight: 900;
      text-align: center;
      line-height: 1.1;
      margin: 0;
      padding: 0.5rem;
      font-size: 1.6rem;

      span {
        color: #1CE6C2;
        font-size: 3rem;
        margin-bottom: 0.5rem;

      }
    }

    .normal-text {
      text-transform: initial;
      font-size: 1.2rem;
      font-weight: initial;
      max-width: 33rem;
      padding: 0 3rem;

      p {
        margin: 1rem 0;
      }

      ul {
        list-style: none;
        margin: 1rem 0;
        padding-left: 0.5rem;

        li {
          &:before {
            content: '>';
            color: $blue_1;
            font-weight: 900;
            margin-right: 0.5rem;

          }
        }
      }
    }

    p {
      text-align: center;
      margin: 0;
    }

    .big-text {
      opacity: 0;
      font-size: 1.45rem;
      margin-top: auto;
      color: $blue_5;
      line-height: 1.1;
      padding-bottom: 1.4rem;
      position: relative;
      z-index: 5;
      .little{
        font-size: 1.2rem;
        font-weight: normal;
        text-transform: none;
        max-width: 25rem;
      }
    }

    .text-with-line {
      font-size: 1.2rem;
      position: relative;
      margin-bottom: 1rem;

      &:after {
        content: "";
        position: absolute;
        left: 80%;
        top: 50%;
        transform: translateY(-50%);
        width: 5rem;
        height: 0.2rem;
        background-color: $blue_5;
      }

      &:before {
        content: "";
        position: absolute;
        right: 80%;
        top: 50%;
        transform: translateY(-50%);
        width: 5rem;
        height: 0.2rem;
        background-color: $blue_5;
      }
    }

    .surligned {
      background-color: $blue_5;
      font-size: 1.8rem;
      color: $white;
      padding: 0.3rem 0.5rem;
      line-height: 1.7;
    }
  }
}

.img-part {
  position: relative;
  width: 100%;
  margin-top: 1rem;

  img {
    position: absolute;
    z-index: 1;
  }

  .background-gradient {
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 282rem;
    height: 282rem;
    background: linear-gradient(87deg, #25E5DA 50%, #24FA94 55%);
    border-radius: 100%;
    z-index: 0;
  }
}

.avantages {

  article {
    height: 4.4rem;
    overflow-y: hidden;
    border-bottom: 1px solid $blue_1;
    border-top: 1px solid $blue_1;
    transition: height 0.7s ease;

    .clickable-part {
      cursor: pointer;
      width: 100%;
      background-color: $blue_5;
      transition: background-color 0.5s ease, width 0.7s ease;

      &:hover {
        background-color: lighten($blue_5, 5%);
      }
    }

    &.open {
      //height: 36.8rem;
      height: 40.8rem;

      .big-text {
        opacity: 1;
        transition: 0.5s ease;
        transition-delay: 0.5s;
      }


      .clickable-part {
        width: 17rem;
      }
    }
  }
}

.first-item {
  .clickable-part {
    width: 100%;
    transition: width 0.5s ease 0.7s;
  }

  .jeep-rubicon {
    max-height: 8rem;
    top: 0;
    opacity: 0;
    left: calc(50% + 5rem);
    transform: translateX(-50%);
    transition: left 0.5s ease, opacity 0.5s;
  }

  &.open {
    .jeep-rubicon {
      opacity: 1;
      left: 50%;
      transition-delay: 0.8s;
    }
  }
}

.second-item {
  .mercedes-amg {
    top: 1.2rem;
    opacity: 0;
    left: calc(50% + 20rem);
    transform: translateX(-50%);
    transition: left 0.5s ease, opacity 0.5s;
    height: 8rem;

  }

  .whiteCar {
    top: 1.7rem;
    opacity: 0;
    left: calc(50% - 20rem);
    transform: translateX(-50%);
    transition: left 0.5s ease, opacity 0.5s;
    height: 7.5rem;
  }

  &.open {
    .mercedes-amg {
      left: calc(50% + 12rem);
      opacity: 1;
      transition-delay: 0.8s;
    }

    .whiteCar {
      left: calc(50% - 12rem);
      opacity: 1;
      transition-delay: 0.8s;

    }
  }
}

.third-Item {
  .container-liked-white-car {
    position: absolute;
    top: 3rem;
    right: 50%;
    display: block;
    padding: 1.5rem;
    transform: translateX(50%);
    max-width: 18.7rem;
    min-width: 18rem;
    z-index: 2;
  }

  .corner {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    opacity: 0;
    transition: opacity 0.2s,
    top 0.2s,
    right 0.2s,
    bottom 0.2s,
    left 0.2s;

    &.corner-top-left {
      top: -4rem;
      left: -4rem;
      color: $blue_1;
      transform: rotate(-90deg);
    }

    &.corner-top-right {
      top: -4rem;
      right: -4rem;
      color: $blue_1;
    }

    &.corner-bottom-right {
      bottom: -4rem;
      right: -4rem;
      color: $white;
      transform: rotate(90deg);
    }

    &.corner-bottom-left {
      bottom: -4rem;
      left: -4rem;
      color: $white;
      transform: rotate(180deg);
    }
  }

  .hearth-white-car {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%) rotateY(90deg);
    width: 2.5rem;
    height: 2.5rem;
    opacity: 0;
    z-index: 0;
    color: $blue_1;
  }

  .liked-white-car {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s;
  }

  &.open {
    .corner {
      opacity: 1;
      transition: opacity 0.8s 0.6s,
      top 0.3s 0.8s,
      right 0.3s 0.8s,
      bottom 0.3s 0.8s,
      left 0.3s 0.8s;

      &.corner-top-left {
        top: 0;
        left: 0;
      }

      &.corner-top-right {
        top: 0;
        right: 0;
      }

      &.corner-bottom-right {
        bottom: 0;
        right: 0;
      }

      &.corner-bottom-left {
        bottom: 0;
        left: 0;
      }
    }

    .hearth-white-car {
      animation: bouncingHearth 0.8s ease-in-out forwards 1s;
    }

    @keyframes bouncingHearth {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 1;
        bottom: 95%;
      }
      70% {
        bottom: 85%;
        transform: translateX(-50%) rotateY(-35deg);

      }
      100% {
        transform: translateX(-50%) rotateY(0deg);
        opacity: 1;
        bottom: 90%;
      }
    }

    .liked-white-car {
      opacity: 1;
    }
  }

  .background-gradient {
    top: 8rem;
  }
}

.fourth-item {
  .maison {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    max-width: 32rem;
    z-index: 2;
  }

  .driving-car {
    width: 6.9rem;
    z-index: 3;
    top: 7.5rem;
    left: calc(50% + -4rem);
    opacity: 0;
    transform: translateX(-50%) scale(2);
    transition: left 0.5s, top 0.5s, opacity 0.2s, transform 0.5s;

  }

  &.open {
    .driving-car {
      top: 5.5rem;
      left: calc(50% + 4rem);
      opacity: 1;
      transform: translateX(-50%) scale(1);
      transition-delay: 0.8s;

    }
  }
}


@media screen and (min-width: 768px) {
  .avantages {
    padding: 6rem 10%;

    article {
      .normal-text {
        font-size: 1.4rem;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .avantages {
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 20%, rgba(172, 197, 205, 1) 100%);
    background-size: 100% 200%;
    background-position-x: 50%;
    background-position-y: 200%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 5rem 0 10rem;

    .title-part {
      width: 100%;
      height: auto;
      background: none;

      &.open {
        height: auto;
      }

      h2 {
        margin-top: 0;
        margin-bottom: 5rem;
        font-size: 3rem;
        line-height: 1.3;

        .white {
          background-color: $blue_5;
          padding: 0.3rem 0.5rem;
          margin: 0.5rem 0;
        }
      }

      svg {
        display: none;
      }
    }

    article {
      height: auto;
      background: none;
      border: none;
      overflow: initial;
      width: 23rem;
      position: initial;

      &:last-of-type {
        .clickable-part {
          &:after {
            display: none;
          }
        }
      }

      .clickable-part {
        background-color: $white;
        position: relative;
        order: 0;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -2;
          width: 0.3rem;
          height: 60%;
          background-color: $blue_1;
          z-index: 5;

        }

        &:hover {
          background-color: darken($white, 5%);
        }

        h3 {
          text-align: left;
          padding: 0;
          line-height: 1.2;
          padding: 1rem 3rem;
          font-size: 1.8rem;
          color: $blue_5;

          span {
            line-height: 1;
            margin-bottom: 0;
          }
        }
      }

      .big-text {
        order: 1;
        margin-top: 2rem;
        line-height: 1.3;
        transition: opacity 0.5s;

        p {
          font-size: 1.6rem;
        }

        .text-with-line {
          &:after {
            left: 88%;
            width: 2.5rem;
          }

          &:before {
            right: 88%;
            width: 2.5rem;
          }
        }
      }

      .img-part {
        order: 2;
        position: absolute;
        top: 50rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        opacity: 0;
      }

      .normal-text {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        order: 3;
        opacity: 0;
        transition: opacity 0.7s;
        font-size: 1.6rem;

        ul {
          li {
            &:before {
              color: $white;
            }
          }
        }
      }

      &.first-item {
        .jeep-rubicon {
          max-height: none;
          width: 30rem;
          top: -6rem;
        }
      }

      &.second-item {
        .mercedes-amg {
          height: 13rem;
          top: 1rem;
        }

        .whiteCar {
          height: 12.5rem;
          top: 2rem;
        }

        &.open {
          .mercedes-amg {
            left: calc(50% + 17rem);
          }

          .whiteCar {
            left: calc(50% - 17rem);
          }
        }
      }

      &.third-Item {
        .background-gradient {
          top: 5rem;
        }

        .container-liked-white-car {
          top: 0rem;
          max-width: 34rem;

          .hearth-white-car {
            width: 4rem;
            height: 4rem;
          }

          .corner {
            width: 2rem;
            height: 2rem;
          }
        }
      }

      &.fourth-item {
        .maison {
          opacity: 0;
          transition: opacity 0.5s;
          max-width: 60rem;
          top: -2.9rem;
          transform: translateX(-66%);
        }

        .driving-car {
          top: 11rem;
          left: calc(50% - 18rem);
          width: 10rem;
          transition: left 1s, top 1s, opacity .4s, transform 1s;
          transition-delay: 0.8s;
        }

        &.open {
          .maison {
            opacity: 1;
          }

          .driving-car {
            top: 5rem;
            left: calc(50% + 8rem);
          }
        }
      }

      &.open {
        .clickable-part {
          width: 100%;
          background-color: $blue_5;

          h3 {
            color: $white;
          }
        }

        .img-part {
          opacity: 1;
          z-index: 1;
        }

        .normal-text {
          z-index: 5;
          max-width: 50rem;
          opacity: 1;
          transition-delay: 0.7s;
        }
      }

    }
  }
}