.actuality {
  background-color: $white;
  padding: 2rem 0;

  .actu-content {
    width: 80%;
    max-width: 78rem;
    margin: auto;
    .ariane {
      margin: 0;
      font-size: 1.4rem;
      color: $color-text;

      a:visited {
        color: $color-text;
      }

      a:hover {
        color: $blue_7;
      }
    }
    .img-container {
      width: 100%;
      position: relative;

      .date {
        width: 100%;
        margin: 0;
        background: $linear-gradient-flashy;
        padding: 0.8rem;
        display: flex;
        align-items: baseline;
        border-top-right-radius: 1.5rem;
        font-weight: 500;

        .day {
          font-size: 2rem;
        }

        .month {
          font-size: 1.4rem;
          margin: 0 0.4rem;
        }

        .year {
          margin: 0 0.4rem;
          font-size: 1.6rem;
          color: $white;
        }

      }

      .img-actu {
        border-bottom-left-radius: 1.5rem;
        overflow: hidden;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
        }


        img {
          width: 100%;
          max-height: 30.4rem;
          height: 30vw;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    h2 {
      position: relative;
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 2rem;
      margin-left: 1rem;
      color: $white;
      line-height: 1.2;
      padding: 1rem 2rem;
      background-color: $blue_5;
      border-radius: 0 0 1rem 0;

      &:before {
        position: absolute;
        content: '';
        width: 1rem;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        left: -1rem;
        background: linear-gradient(to bottom, #24FA94 0%, #25E5DA 100%);
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .actuality {
    .actu-content {
      .img-container {
        .date {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: absolute;
          width: 5.5rem;
          left: -5.5rem;
          height: 100%;
          border-radius: 0 0 0 1rem;
          line-height: 1.2;

          .day {
            font-size: 2.4rem;
          }
        }

        .img-actu {
          border-radius: 0 1rem 0 0;

          img {
            max-height: 40rem;
          }
        }

        h2 {
          font-size: 2.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .actuality {
    .actu-content {
    }
  }
}

@media screen and (min-width: 1920px) {
  .actuality {
    .actu-content {
      h2 {
        font-size: 2.6rem;
      }
    }
  }
}