@import "header";
@import "footer";

html {
  font-size: 62.5%; // ex: 1rem = 10px | 1.6rem = 16px...
  scroll-behavior: smooth;
}


body {
  position: relative;
  font-size: $font-size-text;
  font-family: REM, sans-serif;
  font-weight: $font-weight-text;
  line-height: $font-line-height-text;
  color: $color-text;
  background-color: $color-background;

  > .page{
    display: flex!important;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;

    main {
      flex-grow: 1;
      background-color: $blue_5;
    }
  }
}

::selection {
  color: $white;
  background-color: $blue_1;
}
